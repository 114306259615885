import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setMerchantType } from "app/appSlice";
import { initialState } from "app/initialState";
import { getMerchantType, updateMerchantType } from "api/services/merchantTypeService";
import { APP_MERCHANTTYPE_LIST } from "constants/AppRouteName";
import { updateToastAgent } from "app/appSlice";
import { Formik, Form, ErrorMessage } from "formik";
import { merchantTypeValidationSchema } from "app/validationSchema";
import * as Yup from "yup";

export default function EditMerchantType() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { merchantType } = useSelector((state) => state.appStore);
    const { name } = merchantType ?? initialState.merchantType;
    const { merchantTypes } = useSelector((state) => state.appStore);
    let merchanttypeArray = merchantTypes
        ? merchantTypes
            .filter(item => item.name !== name)
            .map(item => item.name.toLowerCase())
        : [];

    useEffect(() => {
        dispatch(getMerchantType({ id }));
    }, [id]);

    const initialValues = {
        name: name || "",
    };

  

    const handleUpdate = async (values, actions) => {
        if (merchanttypeArray.includes(values.name.toLowerCase())) {
            dispatch(updateToastAgent({ t: "e", m: "Merchant Type already exists" }));
        } else {
            dispatch(updateMerchantType({ ...merchantType, name: values.name }, APP_MERCHANTTYPE_LIST, navigate));
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" textAlign="center">
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Merchant Type
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter merchant type details to edit
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik initialValues={initialValues} validationSchema={merchantTypeValidationSchema} onSubmit={handleUpdate} enableReinitialize>
                        {(formik) => {
                            const { values, handleChange, handleBlur, errors, touched } = formik;
                            return (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <MDBox mb={2}>
                                                <MDInput
                                                    type="text"
                                                    label="Name"
                                                    name="name"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                    fullWidth
                                                    className={errors.name && touched.name ? "input-error" : null}
                                                />
                                                <ErrorMessage name="name" component="span" className="error" />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6} mt={4} mb={1} display="flex" justifyContent="center">
                                            <MDButton variant="gradient" color="info" type="submit">
                                                Update
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </Card>
        </DashboardLayout>
    );
}
