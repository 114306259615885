import Icon from "@mui/material/Icon";
import * as ROUTE from "constants/AppRouteName";
import SignOut from "customComponents/auth/SignOut";

import adminRoutes from "./adminRoutes";
import guestRoutes from "./guestRoutes";
import loRoutes from "./loRoutes";
import merchantRoutes from "./merchantRoutes";

export {
  adminRoutes,
  guestRoutes,
  loRoutes,
  merchantRoutes
};

const routes = [  
    {
      type: "collapse",
      name: "Sign Out",
      key: "signout",
      icon: <Icon fontSize="small">logout</Icon>,
      route: "/logout",
      component: <SignOut />,
      nave:"internal"
    },
  ];
  
  export default routes;