import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { INSTALLMENT_MANAGER } from "constants/AppRouteName";
import {getCustomersInstallmentList} from '../../api/services/installmentService'


export default function TodaysEmiList() {
  const dispatch = useDispatch();
  const { todaysInstallmentList } = useSelector((state) => state.appStore);
  console.log(todaysInstallmentList)
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
        name: "customer_id",
        label: "Id",
        accessor: "customer_id",
        options: {
          filter: true,
          sort: true,
          empty: true,
          display: true,
          viewColumns: true,
          setCellProps: () => ({
            style: {
              textAlign: 'right',
              paddingRight: '40px',
            },
          }),
        },
      },
    {
      name: "CustomerDetail.name",
      label: " Name",
      accessor: "CustomerDetail.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "LoanDetail.amount",
      label: "Loan Amount",
      accessor: "LoanDetail.amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const loan_amount = Number(tableMeta.rowData[2]).toLocaleString();
         
          return <p>{loan_amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'right',
          paddingRight: '40px',
        },
      }),
      },
    },
    {
      name: "LoanDetail.installments",
      label: "Total Number of EMI",
      accessor: "LoanDetail.installments",
      options: {
        filter: true,
        sort: true,
        empty: true,
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '40px',
          },
        }),
      },
    },
    {
      name: "payment_status",
      label: "Loan Status",
      accessor: "payment_status",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          return <>{tableMeta.rowData[4] === false? "Active" : "closed"}</>;
        },
      },
    },
    {
      name: "payment_amount",
      label: "Installment Amount",
      accessor: "payment_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const installment_amount = Number(tableMeta.rowData[5]).toLocaleString();
         
          return <p>{installment_amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'right',
          paddingRight: '40px',
        },
      }),
      },
    },
    {
      name: "pending_since_days",
      label: "Pending Since Days",
      accessor: "pending_since_days",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData[6])
          
          return <>{tableMeta.rowData[6] === undefined? 0 : tableMeta.rowData[6]}</>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '40px',
          },
        }),
      },
    },
    {
      name: "loan_invoice_id",
      label: "Action",
      accessor: "loan_invoice_id",
      options: {
        filter: true,
        sort:false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(`${INSTALLMENT_MANAGER}/edit/${tableMeta.rowData[7]}`)


    
          return (
            
            <>
              <Link to={`${INSTALLMENT_MANAGER}/edit/${tableMeta.rowData[7]}`}>
                <MDButton color="info" size="small">
                  View
                </MDButton>
              </Link>
              
            </>
          );
        },
      },
    },
  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
  
  };
  useEffect(async () => {
    getCustomersInstallmentList(dispatch)
  }, []);






  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {todaysInstallmentList != null && (
                <MUIDataTable
                  title={"Today's Emi"}
                  data={todaysInstallmentList}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    
    </DashboardLayout>
  );
}
