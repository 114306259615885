
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "api/services/userService";
import { useEffect, useState } from "react";


export default function EditProfile() {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [LastName , setLastName] = useState('')
    const [AlternateNumber , setAlternateNumber] = useState('')
    const [Address , setAddress] = useState('')
    const { data } = useSelector((state) => state.appStore);
    console.log(data)

    useEffect(() => {
        setName(data?.first_name)
        setLastName(data?.last_name)
        setAddress(data?.address)
        setAlternateNumber(data?.alternate_phone_number)

    }, [data]);

    const handleUpdate = async () => {
        let userData ={
            first_name : name , 
            last_name : LastName , 
            merchant_type : data.merchant_type , 
            id : data.id , 
            address : Address , 
            alternate_phone_number : AlternateNumber
        } 
        // console.log({...data , name ,LastName , AlternateNumber , Address })
        dispatch(updateUser(userData))
     
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Profile
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter Profile details to Edit
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="First Name" name=" First name" value={name} variant="standard" onChange={(e) => setName(e.target.value)} fullWidth />
                                </MDBox>

                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label="Last Name" name="Last name" value={LastName} variant="standard" onChange={(e) => setLastName(e.target.value)} fullWidth />
                                        </MDBox>
                                    </Grid>

                            {data.role === 2 && (
                                <>
                                    
                                    <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label="Address" name="Address" value={Address} variant="standard" onChange={(e) => setAddress(e.target.value)} fullWidth />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label="AlternateNumber" name="AlternateNumber" value={AlternateNumber} variant="standard" onChange={(e) => setAlternateNumber(e.target.value)} fullWidth />
                                        </MDBox>
                                    </Grid>
                                </>
                            )}


                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleUpdate}>
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
