import Icon from "@mui/material/Icon";
import * as ROUTE from "constants/AppRouteName";
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
// User
import CreateUser from "customComponents/user/create";

import ManageLoan from "customComponents/loan/list";
import LoanView from "customComponents/loan/view";


const loRoutes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
        nave: "sidebar"
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: ROUTE.APP_PROFILE,
      component: <Profile />,
      nave:"internal"
    },
    {
        type: "collapse",
        name: "Loan Manager",
        key: "loanmanager",
        icon: <Icon fontSize="small">inventory</Icon>,
        route: "/loans",
        component: <ManageLoan />,
        nave:"sidebar"
    },
    {
        type: "collapse",
        name: "Loan View",
        key: "loan view",
        icon: <Icon fontSize="small">inventory</Icon>,
        route: "/loans/view/:id",
        component: <LoanView />,
        nave:"internal"
      },
];

export default loRoutes;