import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect from "@mui/material/NativeSelect";
import { TextField } from "@material-ui/core";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoan } from "api/services/loanService";

export default function ViewLoan() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: user, loan } = useSelector((state) => state.appStore);
  let loanStatus = "Accept";

  if (loan?.status == 1) {
    loanStatus = "Approve";
  } else if (loan?.status == 2) {
    loanStatus = "Reject";
  } else if (loan?.status == 0) {
    loanStatus = "Pending";
  }
  let forecast;
  if (loan?.CustomerModel?.forecast) {
    forecast = JSON.parse(loan.CustomerModel.forecast);
  }
  let expenses;
  if (loan?.CustomerModel?.expenses) {
    expenses = JSON.parse(loan.CustomerModel.expenses);
  }
  let expenses_categorization;
  if (loan?.CustomerModel?.expenses_categorization) {
    expenses_categorization = JSON.parse(loan.CustomerModel.expenses_categorization);
  }
  console.log(expenses_categorization);
  useEffect(() => {
    dispatch(getLoan({ id }));
  }, [id]);

  return (
    // <CoverLayout image={bgImage}>
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {loan?.CustomerModel?.name}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Personal Information
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="First Name"
                    name="first_name"
                    value={loan?.CustomerModel?.first_name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Last Name"
                    name="last_name"
                    value={loan?.CustomerModel?.last_name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Date of Birth"
                    name="dob"
                    value={loan?.CustomerModel?.dob}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nationality"
                    name="nationality"
                    value={loan?.CustomerModel?.CountryModel?.name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Marital Status"
                    name="marital_status"
                    value={loan?.CustomerModel?.marital_status}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Email"
                    name="email"
                    value={loan?.CustomerModel?.email}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="ID Number"
                    name="id_number"
                    value={loan?.CustomerModel?.id_number}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="ID expiry date"
                    name="expiry"
                    value={loan?.CustomerModel?.expiry}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Phone"
                    name="phone"
                    value={loan?.CustomerModel?.phone}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} p={1}>
              Loan Application Information
            </MDTypography>
          </MDBox>

          <MDBox component="form" role="form" pt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Affordability"
                    name="affordability"
                    value={loan?.CustomerModel?.affordability}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Average Monthly Salary"
                    name="average_monthly_salary"
                    value={loan?.CustomerModel?.average_monthly_salary}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox>
                  <NativeSelect
                    id="recurring_expenses_monthly"
                    name="recurring_expenses_monthly"
                    defaultValue={"Recurring Expenses Monthly"}
                    fullWidth
                  >
                    <option value="Recurring Expenses Monthly" disabled selected>
                      Recurring Expenses Monthly
                    </option>
                    {expenses
                      ? expenses?.data?.recurring_expenses_monthly.map((item, index) => {
                          return (
                            <option key={index} disabled style={{ color: "#000" }}>
                              {`Year:${item.year}    Month:${item.month}    Amount:${item.amount}`}
                            </option>
                          );
                        })
                      : ""}
                  </NativeSelect>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <NativeSelect
                    id="monthly_sum_expenses"
                    name="monthly_sum_expenses"
                    defaultValue={"Monthly Sum Expenses"}
                    fullWidth
                  >
                    <option value="Monthly Sum Expenses" disabled selected>
                      Monthly Sum Expenses
                    </option>
                    {expenses
                      ? expenses?.data?.monthly_sum_expenses.map((item, index) => {
                          return (
                            <option key={index} disabled style={{ color: "#000" }}>
                              {`Year:${item.year}    Month:${item.month}    Amount:${item.amount}`}
                            </option>
                          );
                        })
                      : ""}
                  </NativeSelect>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Monthly Affordability Amount"
                    name="monthly_affordability_amount"
                    value={loan?.CustomerModel?.monthly_affordability_amount}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mt={1.5}>
                  <NativeSelect
                    id="merchant_type"
                    name="merchant_type"
                    defaultValue={"Forecasts"}
                    fullWidth
                  >
                    <option value="Forecasts" disabled selected>
                      Forecasts
                    </option>
                    {forecast
                      ? forecast.forecasts.map((item, index) => {
                          return (
                            <option key={index} disabled style={{ color: "#000" }}>
                              {`Days:${item.days} Forecast:${item.forecast} Confidence:${item.confidence}`}
                            </option>
                          );
                        })
                      : ""}
                  </NativeSelect>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Net Worth"
                    name="net_worth"
                    value={loan?.CustomerModel?.net_worth}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="On Time Payment Percentage"
                    name="on_time_payment_percentage"
                    value={
                      loan?.CustomerModel?.on_time_payment_percentage
                        ? `${loan?.CustomerModel?.on_time_payment_percentage}%`
                        : ""
                    }
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mt={1.5}>
                  <NativeSelect
                    id="expenses_categorization"
                    name="expenses_categorization"
                    defaultValue={"Last 30 Days Expenses Categorization"}
                    fullWidth
                  >
                    <option value="Last 30 Days Expenses Categorization" disabled selected>
                      Last 30 Days Expenses Categorization
                    </option>
                    {expenses_categorization
                      ? expenses_categorization?.data?.last_thirty_days_rolling.map(
                          (item, index) => {
                            return (
                              <option key={index} disabled style={{ color: "#000" }}>
                                {`Category:${item.category} Percentage:${item.percentage}%  Amount:${item.amount}`}
                              </option>
                            );
                          }
                        )
                      : ""}
                  </NativeSelect>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Credit Score"
                    name="credit_score"
                    value={loan?.CustomerModel?.credit_score}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Employment Status"
                    name="employment_status"
                    value={loan?.LoanDataModel.EmploymentModel?.name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Status"
                    name="status"
                    value={loanStatus}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} p={1}>
              Loan Information
            </MDTypography>
          </MDBox>
          <MDBox component="form" role="form" pt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Total Loan Amount"
                    name="total_loan_amount"
                    value={loan?.amount}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Number Of Installments"
                    name="number_of_installments"
                    value={loan?.installments}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Total Product Amount"
                    name="total_product_amount"
                    value={loan?.product_amount}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="High Risk"
                    name="high_risk"
                    value={loan?.is_high_risk}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} p={1}>
              IBAN Information
            </MDTypography>
          </MDBox>
          <MDBox component="form" role="form" pt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="IBAN NO."
                    name="iban"
                    value={loan?.LoanDataModel.iban}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Bban"
                    name="bban"
                    value="BARC10201530093459"
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Bank Account"
                    name="bank_account"
                    value="30093459"
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Bank Name"
                    name="bank_name"
                    value="BARCLAYS BANK UK PLC"
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} p={1}>
              Applicant Documents
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Country ID</h4>
                    <a
                      href={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.document}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.document}`}
                        srcSet={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.document}`}
                        alt={`${loan?.CustomerModel?.document}`}
                        loading="lazy"
                        height="200"
                        width="200"
                      />
                    </a>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Selfie</h4>
                    <a
                      href={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.profile}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.profile}`}
                        srcSet={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.profile}`}
                        alt={`${loan?.CustomerModel?.profile}`}
                        loading="lazy"
                        height="200"
                        width="200"
                      />
                    </a>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Utility Bill</h4>
                    <a
                      href={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.address_image}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.address_image}`}
                        srcSet={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.address_image}`}
                        alt={loan?.CustomerModel?.address_image}
                        loading="lazy"
                        height="200"
                        width="200"
                      />
                    </a>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Payslip</h4>
                    <a
                      href={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.payslip}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.payslip}`}
                        srcSet={`http://103.74.54.116:3000/documents/${loan?.CustomerModel?.payslip}`}
                        alt={loan?.CustomerModel?.payslip}
                        loading="lazy"
                        height="200"
                        width="200"
                      />
                    </a>
                  </MDBox>
                </Grid>
                {loan?.invoice ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mb={2}>
                      <h4>Invoice</h4>
                      <a
                        href={`http://103.74.54.116:3000/documents/${loan?.invoice}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`http://103.74.54.116:3000/documents/${loan?.invoice}`}
                          srcSet={`http://103.74.54.116:3000/documents/${loan?.invoice}`}
                          alt={loan?.invoice}
                          loading="lazy"
                          height="200"
                          width="200"
                        />
                      </a>
                    </MDBox>
                  </Grid>
                ) : (
                  false
                )}
                {loan?.agreement ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox mb={2}>
                      <h4>Agreement</h4>
                      <a
                        href={`http://103.74.54.116:3000/agreement/signed/${loan?.agreement}`}
                        target="_blank"
                        rel="noreferrer"
                        title={loan?.agreement}
                      >
                        Agreement
                      </a>
                    </MDBox>
                  </Grid>
                ) : (
                  false
                )}
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
    // </CoverLayout>
  );
}
