import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getNewLoans} from '../../api/services/loanService'; 
import Tooltip from '@mui/material/Tooltip';
import MDButton from "components/MDButton";


export default function NewLoan() {
  const dispatch = useDispatch();
  const { newLoans } = useSelector((state) => state.appStore);
  const date = new Date();
  let formattedDate = date.toISOString().split('T')[0];
  // console.log(formattedDate);


  console.log(newLoans)
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
   
    {
      name: "CustomerModel.name",
      label: " Name",
      accessor: "CustomerModel.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "amount",
      label: "Loan Amount",
      accessor: "amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const loan_amount = Number(tableMeta.rowData[1]).toLocaleString();
         
          return <p>{loan_amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'right',
          paddingRight: '80px',
        },
      }),
      },
    },
    {
        name: "product_amount",
        label: "Product Amount",
        accessor: "product_amount",
        options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const product_amount = Number(tableMeta.rowData[2]).toLocaleString();
           
            return <p>{product_amount}</p>;
        } , 
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '80px',
          },
        }),
        },
      },
    {
      name: "installments",
      label: "Total Number of EMI",
      accessor: "installments",
      options: {
        filter: true,
        sort: true,
        empty: true,
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '80px',
          },
        }),
      },
     
    },
    {
      name: "first_installment_amount",
      label: "Installment Amount",
      accessor: "first_installment_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const installment_amount = Number(tableMeta.rowData[4]).toLocaleString();
         
          return <p>{installment_amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'right',
          paddingRight: '80px',
        },
      }),
      },
    },
    {
        name: "is_high_risk",
        label: "Is High Risk",
        accessor: "is_high_risk",
        options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(tableMeta.rowData)
            
            return <>{tableMeta.rowData[5] === false? <Tooltip title='Low Risk'><MDButton color='success' size='small' disabled>Low Risk</MDButton></Tooltip> : <Tooltip title='High Risk'><MDButton color='error' size='small' disabled>High Risk</MDButton></Tooltip>}</>;
          },
        },
      },

  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
  
  };


  useEffect(async () => {
  
    getNewLoans(dispatch)
   
  }, []);






  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">

        <MDBox px={2} py={2}>
          <p style={{textAlign:'right'}}>{'Date:'+ " "+ formattedDate}</p>
        </MDBox>
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {newLoans != null && (
                <MUIDataTable
                  title={"New Loans"}
                  data={newLoans}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    
    </DashboardLayout>
  );
}
