import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "api/services/categoryService";
import { APP_CATEGORY_LIST } from "constants/AppRouteName";
import { updateToastAgent } from "app/appSlice";
import { Formik, Form, ErrorMessage } from "formik";
import { categoryValidationSchema } from "app/validationSchema";
import * as Yup from "yup";

export default function AddCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state.appStore);

  let categoriesArray = categories ? categories.map((item) => item.name.toLowerCase()) : [];

  const initialValues = {
    name: "",
    description: "",
  };

  

  const handleCreate = async (values, actions) => {
    let data = JSON.stringify({
      name: values.name,
      description: values.description,
    });

    if (categoriesArray.includes(values.name.toLowerCase())) {
      dispatch(updateToastAgent({ t: "e", m: "Product Category already exists" }));
      actions.resetForm();
    } else {
      dispatch(addCategory(data, APP_CATEGORY_LIST, navigate));
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create Category
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter Category details to create
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik initialValues={initialValues} validationSchema={categoryValidationSchema} onSubmit={handleCreate}>
            {(formik) => {
              const { values, handleChange, handleBlur, errors, touched } = formik;
              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Name"
                          name="name"
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          fullWidth
                          className={errors.name && touched.name ? "input-error" : null}
                        />
                        <ErrorMessage name="name" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Description"
                          name="description"
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          fullWidth
                          className={errors.description && touched.description ? "input-error" : null}
                        />
                        <ErrorMessage name="description" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                      <MDButton variant="gradient" color="info" type="submit">
                        Create
                      </MDButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
