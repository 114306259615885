import Icon from "@mui/material/Icon";
import * as ROUTE from "constants/AppRouteName";
// Auth
import SignIn from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/reset-password/cover";
import EnterOTP from "layouts/authentication/reset-password/cover/enterOTP";
import NewPassword from "layouts/authentication/reset-password/cover/newPassword";

const guestRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <SignIn />,
    nave: "external",
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset password",
    icon: <Icon fontSize="small">lock</Icon>,
    route: ROUTE.APP_RESET_PASSOWRD,
    component: <ResetPassword />,
    nave: "external",
  },
  {
    type: "collapse",
    name: "Enter OTP",
    key: "Enter OTP",
    icon: <Icon fontSize="small">lock</Icon>,
    route: '/OTP',
    component: <EnterOTP />,
    nave: "external",
  },
  {
    type: "collapse",
    name: "Set New Password",
    key: "Set New Password",
    icon: <Icon fontSize="small">lock</Icon>,
    route: '/CreateNewPassword',
    component: <NewPassword />,
    nave: "external",
  },
];

export default guestRoutes;
