import React, { useState, useRef } from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { SET_NEW_PASSWORD } from "constants/AppRouteName";
import { useNavigate } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import { setOTP } from "app/appSlice";

function EnterOTP() {
    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const [otpError, setOtpError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const otp2Ref = useRef(null);
    const otp3Ref = useRef(null);
    const otp4Ref = useRef(null);

    function handleInputChange(value, setFunction, nextRef) {
        setFunction(value);
        if (value.length === 1 && nextRef.current) {
            nextRef.current.focus();
        }
    }
   

    function handleOtpSubmit() {
        const otpValue = otp1 + otp2 + otp3 + otp4;
        const otpPattern = /^\d{4}$/;

        if (!otpPattern.test(otpValue)) {
            setOtpError('Please enter a valid 4-digit OTP.');
        } else {
            setOtpError('');
            dispatch(setOTP(otpValue))
            navigate(SET_NEW_PASSWORD)
        }
    }

    return (
        <CoverLayout coverHeight="50vh" image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Confirm OTP
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <div className="otpInputs" style={{display:'flex' , justifyContent:'center'}}>
                        <input type="text" maxLength="1" value={otp1} onChange={(e) => handleInputChange(e.target.value, setOtp1, otp2Ref)} style={{width:'50px' , height:'50px' , margin:'10px' , textAlign:'center' , fontSize:'20px' , borderRadius:'5px'}}/>
                        <input type="text" maxLength="1" value={otp2} onChange={(e) => handleInputChange(e.target.value, setOtp2, otp3Ref)} ref={otp2Ref} style={{width:'50px' , height:'50px' , margin:'10px', textAlign:'center' , fontSize:'20px', borderRadius:'5px'}} />
                        <input type="text" maxLength="1" value={otp3} onChange={(e) => handleInputChange(e.target.value, setOtp3, otp4Ref)} ref={otp3Ref}  style={{width:'50px' , height:'50px' , margin:'10px', textAlign:'center' , fontSize:'20px', borderRadius:'5px'}}/>
                        <input type="text" maxLength="1" value={otp4} onChange={(e) => handleInputChange(e.target.value, setOtp4, otp4Ref)} ref={otp4Ref}  style={{width:'50px' , height:'50px' , margin:'10px', textAlign:'center' , fontSize:'20px', borderRadius:'5px'}}/>
                    </div>
                    <p style={{ color: 'red' , margin:'0px 10px' , fontSize:'15px' }}>{otpError}</p>
                    <MDBox mt={6} mb={1} display="flex" justifyContent="center">
                        <MDButton
                            type="button"
                            variant="gradient" color="info"
                            onClick={handleOtpSubmit}
                        >
                            Confirm
                        </MDButton>
                    </MDBox>
                    <MDBox mt={6} mb={1}>
                        <MDButton onClick={() => navigate(-1)}>Entered Wrong Mail Id ? Back</MDButton>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default EnterOTP;
