import   {axiosPrivate} from "api/axios";
import * as API from "constants/ApiEndpoint";
import {
    updateLoader,
    updateToastAgent,
    setCustomersInstallmentsList , 
    setSingleCustomerInstallmentsList , 
    setTodaysInstallmentList
} from "app/appSlice";


function flattenArrayOfObjects(arr) {
    return arr.map(obj => flattenObject(obj));
}

function flattenObject(obj, parentKey = '') {
    return Object.keys(obj).reduce((acc, key) => {
        const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj[key])) {
            const flattenedArray = flattenArrayOfObjects(obj[key]);
            flattenedArray.forEach((item, index) => {
                Object.keys(item).forEach(subKey => {
                    acc[`${prefixedKey}[${index}].${subKey}`] = item[subKey];
                });
            });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            const flattenedChild = flattenObject(obj[key], prefixedKey);
            Object.assign(acc, flattenedChild);
        } else {
            acc[prefixedKey] = obj[key];
        }
        return acc;
    }, {});
}

export const getCustomersInstallmentList =   async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.INSTALLMENT_LIST);

        if (response.data.status_code == 200) {
            const flattenedArray = flattenArrayOfObjects(response.data.data.missedEmis);
            const flattenedArray2 = flattenArrayOfObjects(response.data.data.todaysEmis);
           

            dispatch(setCustomersInstallmentsList(flattenedArray));
            dispatch(setTodaysInstallmentList(flattenedArray2));
        } else {
            dispatch(setCustomersInstallmentsList([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getSingleCustomersInstallmentList =   async (dispatch , id) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.SINGLE_CUSTOMER_INSTALLMENT({id : id}));

        if (response.data.status_code == 200) {
            console.log(response)
            dispatch(setSingleCustomerInstallmentsList(response.data.data));
        } else {
            dispatch(setSingleCustomerInstallmentsList([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateInstallmentStatus = async(dispatch , data)=>{
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.post(API.UPDATE_INSTALLMENT_STATUS , data);

        if (response.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: response.data.message })); 
           
        } else {
            dispatch(updateToastAgent({ t: "e", m: response.data.message })); 
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }

}





    
 