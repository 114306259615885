import { useEffect } from "react";
import {axiosPrivate} from "api/axios";
import { useNavigate } from "react-router-dom";
import { logoutSuccess } from "app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateLoader } from "app/appSlice";
export default function SignOut() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.appStore);
    
    useEffect(() => {
        logout();
    }, []);
    const logout = async () => {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(`/logout/${user.id}`);
        if (results.data.status_code == 200) {
            dispatch(logoutSuccess());
            navigate("/login");
        }else{
            dispatch(updateLoader(false));
        }
    }

    return null;
}
