import { axiosPrivate } from "api/axios";
import { setUser } from "app/appSlice";
import {
    updateLoader,
    updateToastAgent,
    setUsers,
    setMerchantLoanData,
    setDashboardData,
} from "app/appSlice";
import { ROLES } from "utils/dataList";
import * as API from "constants/ApiEndpoint";

export const getUsers = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_USERS);
        if (response.data.status_code == 200) {
            response.data.data.forEach((data) => {
                data["role"] = ROLES[data.role] ?? ROLES[0];
            })
            dispatch(setUsers(response.data.data));
        }
        else {
            dispatch(setUsers([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getUser = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_USER({ id: req.id }));
        // console.log(response.data.data.registration_number)
        if (response.data.status_code == 200) {

            let resultData = {
                first_name: response.data.data.first_name,
                last_name: response.data.data.last_name,
                id: response.data.data.id,
                email: response.data.data.email,
                role: response.data.data.role,
                merchant_type:response.data.data.merchant_type,
                comment: response.data.data.comment,
                address: response.data.data.address,
                registered_phone_number: response.data.data.registered_phone_number,
                alternate_phone_number: response.data.data.alternate_phone_number,
                deduct_percent: response.data.deduct_percent , 
                registration_number : response.data.data.registration_number
            }
            await dispatch(setUser(resultData));
            // console.log(resultData)
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const changePassword = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.CHANGE_PASSWORD, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateUser = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.USER, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const createUser = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.CREATE_USER, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message  }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getMerchantLoanAmount = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_MERCHANT_LOAN_AMOUNT);

        if (response.data.status_code == 200) {

            dispatch(setMerchantLoanData(response.data.data));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getDashboardData = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_DASHBOARD_DATA({ role: req.role,id: req.id }));

        if (response.data.status_code == 200) {

            dispatch(setDashboardData(response.data.data));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}
