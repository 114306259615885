import axios from "api/axios";
import {
    updateLoginDetails,
    updateLoader,
    updateToastAgent,
    logoutSuccess,
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

;




export const doLogin = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axios.post(API.LOGIN, req);
        if (results.data.status_code == 200) {
            dispatch(updateLoginDetails(results.data))
        } else {
            dispatch(logoutSuccess());
            dispatch(updateToastAgent({ t: "e", m: results.data.message }));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
    dispatch(updateLoader(false));
}

export const doResetPassword = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axios.post(API.RESET_PASSWORD, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        } else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }));
        }
        dispatch(updateLoader(false));
        return results.data.status_code;
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const setNewPassword = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axios.post(API.SET_USERS_NEW_PASSWORD, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        } else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }));
        }
        dispatch(updateLoader(false));
        return results.data.status_code;
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}