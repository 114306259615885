import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "app/initialState";



export default function UserFincludeDetails() {

    const { id } = useParams();
    const { customer } = useSelector((state) => state.appStore);
    // console.log(customer)
    let {
        affordability,
        average_monthly_salary,
        credit_score,
        expenses,
        monthly_affordability_amount,
        net_worth,
        on_time_payment_percentage,
        forecast , 
        first_name ,
        last_name
    } = customer ?? initialState.customer;

    let forcastArr =  forecast.split(",")




    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        {first_name +" " + last_name}
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Finclude Details
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="affordability"
                                        label="Affordability"
                                        name="affordability"
                                        value={affordability}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>

                                <MDBox mb={2}>
                                    <MDInput
                                        type="average_monthly_salary"
                                        label="Average Monthly Salary"
                                        name="average_monthly_salary"
                                        value={average_monthly_salary}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="credit_score "
                                        name="Credit Score "
                                        value={credit_score}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            {/* <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="Expenses"
                                        name="expenses"
                                        value={expenses}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid> */}

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="Net Worth"
                                        name="net_worth"
                                        value={net_worth}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="On Time Payment Percentage"
                                        name="on_time_payment_percentage"
                                        value={on_time_payment_percentage + '%'}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <div>
                                        <p style={{fontSize:'15px'}}>Forcast</p>
                                        <div style={{fontSize:'15px' , display:'flex'}}>
                                            <p style={{margin:'5px 0px 0px 0px', width:'80px'}}>30 Days</p>
                                            <p style={{margin:'5px 0px 0px 10px', width:'80px'}}>60 Days</p>
                                            <p style={{margin:'5px 0px 0px 10px', width:'80px'}}>90 Days</p>
                                        </div>

                                        <div style={{fontSize:'15px' , display:'flex'}}>
                                            <p style={{margin:'5px 0px 0px 0px' , width:'80px'}}>{forcastArr[0]}</p>
                                            <p style={{margin:'5px 0px 0px 10px', width:'80px'}}>{forcastArr[0]}</p>
                                            <p style={{margin:'5px 0px 0px 10px', width:'80px'}}>{forcastArr[0]}</p>
                                        </div>
                                        
                                    </div>
                                  
                                </MDBox>
                            </Grid>

                            {/* <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="60 Days Forcast "
                                        name="forcast"
                                        value={forcastArr[1]}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="90 Days Forcast "
                                        name="forcast"
                                        value={forcastArr[2]}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid> */}


                        </Grid>
                    </MDBox>

                  

                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
