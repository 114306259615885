import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNewPassword } from 'api/services/authService';
import * as ROUTE from "constants/AppRouteName";
import { ChangePasswordSchema } from 'app/validationSchema';

function NewPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { UserEmail, OTPForResetPassword } = useSelector((state) => state.appStore);

    const handleSubmit = async (values) => {
        try {
            const { new_password: password } = values;
            let data = {
                email: UserEmail,
                password: password,
                otp: OTPForResetPassword
            }
            const statusCode = await dispatch(setNewPassword(data));
            if (statusCode === 200) {
                navigate(ROUTE.APP_LOGIN);
            }
        } catch (error) {
            console.error("Error resetting password:", error);
        }
    };

    return (
        <CoverLayout coverHeight="50vh" image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Reset Password
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Please Enter Your New Password and Confirm It.
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik
                        initialValues={{
                            new_password: '',
                            confirm_password: '',
                        }}
                        validationSchema={ChangePasswordSchema}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <MDBox mb={4}>
                                <Field
                                    as={MDInput}
                                    type="password"
                                    name="new_password"
                                    label="New Password"
                                    fullWidth
                                />
                                <ErrorMessage name="new_password" component="span" className="error" />
                            </MDBox>
                            <MDBox mb={4}>
                                <Field
                                    as={MDInput}
                                    type="password"
                                    name="confirm_password"
                                    label="Confirm Password"
                                    fullWidth
                                />
                                <ErrorMessage name="confirm_password" component="span" className="error" />
                            </MDBox>
                            <MDBox mt={6} mb={1} display="flex" justifyContent="center">
                                <MDButton type="submit" variant="gradient" color="info">
                                    Reset
                                </MDButton>
                            </MDBox>
                        </Form>
                    </Formik>
                    <MDBox mt={6} mb={1}>
                        <MDButton onClick={() => navigate(ROUTE.APP_LOGIN)}>Already have an account ? Login</MDButton>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default NewPassword;
