
import { Link, Navigate, useNavigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";

const FormData = require('form-data');

import { useEffect, useState } from "react";

import {axiosPrivate} from "api/axios";
import { updateLoader } from "app/appSlice";
import { updateToastAgent } from "app/appSlice";

import { importProduct } from "api/services/productService";
import { APP_PRODUCT_LIST } from "constants/AppRouteName";

export default function ImportProducts() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState('');

    const selectFile = ((e) => {
        setFile(e.target.files[0])
    })
    const handleCreate = async () => {

        dispatch(updateLoader(true));
        let data = new FormData();
        // data.append('name', "CSV1");
        data.append('csvFile', file);

        dispatch(importProduct(data, APP_PRODUCT_LIST, navigate));
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Import Products
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Please select products details CSV to import
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="file" accept=".csv" name="Upload File " variant="standard" onChange={(e) => selectFile(e)} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDButton variant="gradient" color="info" onClick={handleCreate}>
                                    Upload
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
