import { InstallmentList } from "api/services/installmentService";
import { revenueReport } from "api/services/revenueReport";
import guestRouts from "routes/guestRoutes";

export const initialState = {
  routes: guestRouts,
  data: null,
  token: null,
  type: null,
  toastAgent: {},
  loader: false,
  users: [],
  user: {
    first_name: "",
    last_name: "",
    id: "",
    email: "",
    role: "",
    merchant_type: "",
    comment: "",
    address:"",
    registered_phone_number: "",
    alternate_phone_number: "",
    deduct_percent: "" , 
    registration_number : ""
  },
  category: {
    name: "",
    id: "",
    description: "",
    GeneralRateOfInterest:"" , 
    HighRiskRateOfInterest:""
  },
  createCountry: {
    name: "",
    code: "",
  },
  createCreditRange: {
    credit_score_from: "",
    credit_score_to: "",
    status: "",
    loan_amount: "",
    interest_rate: "",
    number_of_installments: "",
  },
  country: {
    id: "",
    name: "",
    code: "",
  },
  countries: null,
  categories: [],
  businesses: null,
  business: {
    name: "",
    id: "",
  },
  loantype: {
    id: "",
    name: "",
  },
  loantypes: null,
  occupation: {
    id: "",
    name: "",
    code: "",
  },
  occupations: null,
  product: {
    id: "",
    name: "",
    barcode: "",
    description: "",
    category: "",
    price: "",
    color: "",
    size: "",
  },
  
  customer: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    dob: "",
    nationality: "",
    marital_status: "",
    id_number: "",
    expiry: "",
    phone: "",
    payslip: "",
    address_image: "",
    profile: "",
    document: "",
    status: "",
    role: "",
  },
  employmentstatus: {
    id: "",
    name: "",
  },
  employmentstatuses: null,
  merchantType: {
    name: "",
  },
  signIn: {
    email: "",
    password: "",
  },
  resetPassword: {
    email: "",
  },
  createUser: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "",
    merchant_type:"",
    registered_phone_number: "",
    alternate_phone_number: "",
    deduct_percent: "",
    address: "",
    deductPercent: {
      categoryId: "",
      deductPercent: "",
    },
  },
  editUser: {
    id: "",
    first_name: "",
    last_name: "",
    role: "",
    merchant_type: "",
    comment: "",
  },
  changePassword: {
    id: "",
    old_password: "",
    new_password: "",
  },
  content: {
    id: "",
    title: "",
    staticcontent: "",
  },
  contents: null,
  merchantLoanStatics: null,
  dashboardData: null,
  CustomerInstallmentsList :[] , 
  todaysInstallmentList : [],
  SingleCustomerInstallmentsList : [] ,
  UserEmail: '' , 
  OTPForResetPassword : '' , 
  revenueReportData : [] , 
  newLoans : [] , 
  onGoingLoans : [] , 
  appliedLoans : [] ,
  completedLoans : [] ,
  customers : []
};
