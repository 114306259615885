import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setMerchantTypes,
    setMerchantType
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getMerchantTypes = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_MERCHANT_TYPES);
        if (response.data.status_code == 200) {
            dispatch(setMerchantTypes(response.data.data));
        } else {
            dispatch(setMerchantTypes([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getMerchantType = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_MERCHANTTYPE({ id: req.id }));

        if (response.data.status_code == 200) {
            let formData = {
                id: response.data.data.id,
                name: response.data.data.name
            }
            dispatch(setMerchantType(formData));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateMerchantType = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.GET_MERCHANTTYPE({ id: req.id }), JSON.stringify(req));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const addMerchantType = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.GET_MERCHANT_TYPES, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const deleteMerchantType = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.DELETE_MERCHANTTYPE({ id: req.id }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const acceptLoan = (req) => async (dispatch) => {
    // console.log(API.ACCEPT_LOAN_BY_MERCHANT)
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.ACCEPT_LOAN_BY_MERCHANT , req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}