import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setCategory,
    setCategories,
    logoutSuccess,
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getCategories = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.CATEGORY);

        if (response.data.status_code == 200) {
            dispatch(setCategories(response.data.data));
        } else {
            dispatch(setCategories([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getCategory = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_CATEGORY({ id: req.id }));

        if (response.data.status_code == 200) {
            let formData = {
                id: response.data.data.id,
                name: response.data.data.name,
                description: response.data.data.description ,
                GeneralRateOfInterest :  response.data.data.GeneralRateOfInterest , 
                HighRiskRateOfInterest : response.data.data.HighRiskRateOfInterest
            }
            dispatch(setCategory(formData));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateCategory = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.UPDATE_CATEGORY({ id: req.id }), JSON.stringify(req));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const addCategory = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.CATEGORY, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const deleteCategory = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.DELETE_CATEGORY({ id: req.id }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}