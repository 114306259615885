/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data

import MUIDataTable from "mui-datatables";
import { Card } from "@mui/material";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMerchantLoanAmount, getDashboardData } from "api/services/userService";
import { APP_MERCHANT_LIST, INSTALLMENT_MANAGER, APP_APP_USER_LIST, APP_TODAYS_EMI, APP_ONGOING_LOANS, APP_NEW_LOANS } from "constants/AppRouteName";
import { useNavigate } from "react-router-dom";

function Dashboard() {

	const dispatch = useDispatch();
	const { data: user, merchantLoanStatics, dashboardData } = useSelector((state) => state.appStore);
	const navigate = useNavigate()
	//   console.log(user.id)

	let [role, setRole] = useState(user.role);
	let [id, setId] = useState(user.id);




	const muiCache = createCache({
		"key": "mui",
		"prepend": true
	});

	const getMuiTheme = () => createTheme({
		components: {
			MUIDataTableBodyCell: {
				styleOverrides: {
					root: {
						backgroundColor: "#FF0000"
					}
				}
			}
		}
	})

	const columns = [
		{
			name: "id",
			label: "ID",
			accessor: "id",
			options: {
				filter: true,
				sort: true,
				empty: true,
				"display": false,
				"viewColumns": false
			}
		},
		{
			name: "MerchantModel.name",
			label: "MERCHANT NAME",
			accessor: "name",
			options: {
				filter: true,
				sort: true,
				empty: true
			}
		},
		{
			name: "MerchantModel.email",
			label: "Email",
			accessor: "email",
			options: {
				filter: true,
				sort: true,
				empty: true
			}
		},
		{
			name: "total",
			label: "Amount",
			accessor: "total",
			options: {
				filter: true,
				sort: true,
				empty: true
			}
		},

	]

	const options = {
		filter: false,
		download: false,
		print: false,
		viewColumns: false,
		search: false,
		enableNestedDataAccess: '.',
		selectableRows: "none"
	};


	if (user.role == 1) {
		useEffect(async () => {
			dispatch(getMerchantLoanAmount());
		}, []);
	}

	useEffect(async () => {
		// console.log(id , role)

		dispatch(getDashboardData({ role, id }));
	}, [role, id]);


	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={8}>
						Welcome {user.name} {user.is_super_admin == 1 && '( Super Admin )'}
					</Grid>
				</Grid>
			</MDBox>
			<MDBox py={3}>
				<Grid container spacing={3} >
					{user.role == 1 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate(APP_MERCHANT_LIST) }}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="weekend"
									title="Registered Merchants"
									count={dashboardData?.totalUser}

								/>
							</MDBox>
						</Grid>
					}
					{user.role == 1 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate(APP_APP_USER_LIST) }}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="leaderboard"
									title="Active Users"
									count={dashboardData?.activeUser}
								/>
							</MDBox>
						</Grid>
					}
					{user.role == 2 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }}  onClick={() => { navigate('/loans') }} >
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="store"
									title="Loan Applied"
									count={dashboardData?.loanApplied}
								/>
							</MDBox>
						</Grid>
					}
					{user.role != 2 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate(APP_NEW_LOANS) }} >
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="primary"
									icon="person_add"
									title="New Loan Applications"
									count={dashboardData?.newLoan}
								/>
							</MDBox>
						</Grid>
					}
					{user.role == 1 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate(INSTALLMENT_MANAGER) }}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="primary"
									icon="leaderboard"
									title="Pending EMI"
									count={dashboardData?.missedEmis}

								/>
							</MDBox>
						</Grid>
					}
					{user.role == 1 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate(APP_TODAYS_EMI) }}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="person_add"
									title="Todays EMI"
									count={dashboardData?.todaysEmis}

								/>
							</MDBox>
						</Grid>
					}

					{user.role == 3 &&
						<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="primary"
									icon="person_add"
									title="Agreement Pending"
									count={dashboardData?.agreementPending}
								/>
							</MDBox>
						</Grid>
					}

					<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { user.role == 1 ? navigate(APP_ONGOING_LOANS) : navigate('/loans/history') }}>
						<MDBox mb={1.5}>
							<ComplexStatisticsCard
								color="primary"
								icon="person_add"
								title="Ongoing Loans"
								count={dashboardData?.ongoingLoans}
							/>
						</MDBox>
					</Grid>

					{user.role == 1 &&
				<Grid item xs={12} md={6} lg={3} style={{ cursor: 'pointer' }} onClick={() => { navigate('/completedLoans') }}>
					<MDBox mb={1.5}>
						<ComplexStatisticsCard
							icon="leaderboard"
							title="Completed Loans"
							count={dashboardData?.completedLoans}
						/>
					</MDBox>
				</Grid>
			}
				</Grid>
			</MDBox>
			{/* <Card >
			<MDBox>
				<CacheProvider value={muiCache}>
					<ThemeProvider theme={getMuiTheme()}>
						{merchantLoanStatics != null && <MUIDataTable title={"Merchant list"} data={merchantLoanStatics} columns={columns} options={options} />}
					</ThemeProvider>
				</CacheProvider>
			</MDBox>
		</Card> */}

		
			<Footer />
		</DashboardLayout>
	);
}

export default Dashboard;
