import { axiosPrivate } from "api/axios";
import { updateLoader, updateToastAgent, setCreditRanges, setCreditRange } from "app/appSlice";
import * as API from "constants/ApiEndpoint";


// const navigate = useNavigate()
export const getCreditRanges = (req) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let response = await axiosPrivate.get(API.GET_CREDIT_RANGES);
    if (response.data.status_code == 200) {
      dispatch(setCreditRanges(response.data.data));
    } else {
      dispatch(setCreditRanges([]));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};
export const getCreditRange = (id) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let response = await axiosPrivate.post(API.GET_CREDIT_RANGE, { id });
    if (response.data.status_code == 200) {
      dispatch(setCreditRange(response.data.data));
    } else {
      dispatch(setCreditRange([]));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const createCreditRange = (req, redirectTo, navigate) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let results = await axiosPrivate.post(API.CREATE_CREDIT_RANGE, req);
    if (results.data.status_code == 200) {
      dispatch(updateToastAgent({ t: "s", m: results.data.message }));
      if (redirectTo) {
        navigate(redirectTo);
      }
    } else {
      dispatch(updateToastAgent({ t: "e", m: results.data.message }));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const deleteCreditRange = (id, navigate, redirectTo) => async (dispatch) => {
  try {

    dispatch(updateLoader(true));
    let response = await axiosPrivate.post(API.DELETE_CREDIT_RANGE, { id });
    if (response.data.status_code == 200) {
      dispatch(updateToastAgent({ t: "s", m: response.data.message }));
      dispatch(updateLoader(false))
      if (redirectTo) {
        navigate(redirectTo);
      }
    } else {
        dispatch(updateToastAgent({ t: "s", m: response.data.message }))
        dispatch(updateLoader(false))
        navigate(redirectTo)
    }
   ;
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const updateCreditRange = (data) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));

    let results = await axiosPrivate.post(API.UPDATE_CREDIT_RANGE, data);
    if (results.data.status_code === 200) {
      dispatch(updateToastAgent({ t: "s", m: results.data.message }));
    } else {
      dispatch(updateToastAgent({ t: "e", m: results.data.message }));
    }

    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const changeStatus = (id, Status) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));

    let results = await axiosPrivate.post(API.CHANGE_CREDIT_STATUS, { id, Status });
    if (results.data.status_code === 200) {
      dispatch(updateToastAgent({ t: "s", m: results.data.message }));
    } else {
      dispatch(updateToastAgent({ t: "e", m: results.data.message }));
    }

    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};
