import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getContents } from "api/services/contentService";
import { APP_CREATE_CONTENT } from "constants/AppRouteName";

export default function ContentList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { contents } = useSelector((state) => state.appStore);
    const muiCache = createCache({
        "key": "mui",
        "prepend": true
    });

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FF0000"
                    }
                }
            }
        }
    })

    const columns = [
        {
            name: "id",
            label: "ID",
            accessor: "id",
            options: {
                filter: true,
                sort: true,
                empty: true,
                "display": false,
                "viewColumns": false
            }
        },
        {
            name: "title",
            label: "TITLE",
            accessor: "title",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "Actions",
            label: "ACTIONS",
            accessor: "actions",
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Link to={`/contents/edit/${tableMeta.rowData[0]}`}>
                                <MDButton color="info" size="small">
                                    Edit
                                </MDButton>
                            </Link>
                        </>
                    );
                }
            }
        }
    ]
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none"
    };
    useEffect(async () => {
        dispatch(getContents());
    }, []);

    const handleCreate = async () => {
        navigate(APP_CREATE_CONTENT);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
                <MDBox align="right" marginRight="5%" marginTop="1%" marginBottom="1%">
                    <MDButton align="right" color="info" size="small" onClick={handleCreate}>Create</MDButton>
                </MDBox>
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                            {contents != null && <MUIDataTable title={"Content list"} data={contents} columns={columns} options={options} />}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>
            </Card>
        </DashboardLayout>
    )
}
