import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setEmploymentStatus,
    setEmploymentStatuses,
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getEmploymentStatuses = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.EMPLOYMENTSTATUSES);

        if (response.data.status_code == 200) {
            dispatch(setEmploymentStatuses(response.data.data));
        } else {
            dispatch(setEmploymentStatuses([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getEmploymentStatus = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_EMPLOYMENTSTATUS({ id: req.id }));

        if (response.data.status_code == 200) {
            let formData = {
                id: response.data.data.id,
                name: response.data.data.name
            }
            dispatch(setEmploymentStatus(formData));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateEmploymentStatus = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.GET_EMPLOYMENTSTATUS({ id: req.id }), JSON.stringify(req));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const addEmploymentStatus = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.EMPLOYMENTSTATUSES, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const deleteEmploymentStatus = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.DELETE_EMPLOYMENTSTATUS({ id: req.id }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}