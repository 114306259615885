/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import * as ROUTE from "constants/AppRouteName";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { APP_LOGIN } from "constants/AppRouteName";
import { useNavigate } from "react-router-dom";
import { doResetPassword } from "api/services/authService";
import { useDispatch } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import { ResetPasswordSchema } from "app/validationSchema";
import { initialState } from "app/initialState";
import { setUserEmail } from "app/appSlice";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm =async ({ email }) => {
    dispatch(setUserEmail(email))
    const statusCode = await dispatch(doResetPassword({ email }));
        if (statusCode === 200) {
            navigate(ROUTE.OTP); 
        }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialState.resetPassword}
            validationSchema={ResetPasswordSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const {
                //values,
                handleChange,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty
              } = formik;
              return (
                <Form>
                  <MDBox mb={4}>
                    <MDInput id="email" name="email" type="email" label="Email" fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.email && touched.email ?
                        "input-error" : "null"}
                    />
                    <ErrorMessage name="email" component="span" className="error" />
                  </MDBox>
                  <MDBox mt={6} mb={1} display="flex" justifyContent="center">
                    <MDButton
                      type="submit"
                      variant="gradient" color="info"
                      className={dirty && isValid ? "" : "disabled-btn"}
                      disabled={!(dirty && isValid)}
                    >
                      reset
                    </MDButton>
                  </MDBox>
                  <MDBox mt={6} mb={1}>
                    <MDButton onClick={() => navigate(APP_LOGIN)}>Already have an account ? Login</MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPassword;
