import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getUsers, getUser } from "api/services/userService";
import { APP_CREATE_USER } from "constants/AppRouteName";

export default function MerchantList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: user, users } = useSelector((state) => state.appStore);
   const MerchantData =users!==null? users.filter((item)=>{return item.role ==="MERCHANT"}) : []
//    console.log(MerchantData)
    const muiCache = createCache({
        "key": "mui",
        "prepend": true
    });
    const [superAdmin] = useState(user ? user?.is_super_admin : null);

    async function setUser(id){
            await dispatch(getUser({ id }));
            navigate(`/user/edit/${id}`)
            
    }

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FF0000"
                    }
                }
            }
        }
    })

    const columns = [
        {
            name: "id",
            label: "Id",
            accessor: "id",
            options: {
                filter: true,
                sort: true,
                empty: true,
                "display": false,
                "viewColumns": false
            }
        },
        {
            name: "name",
            label: "Merchant Name",
            accessor: "name",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "email",
            label: "Email",
            accessor: "email",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "registered_phone_number",
            label: "Contact Number",
            accessor: "registered_phone_number",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "alternate_phone_number",
            label: "Alternate Number",
            accessor: "alternate_phone_number",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
       
    ]
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none"
    };
    useEffect(async () => {
        dispatch(getUsers());
    }, []);

    const handleCreate = async () => {
        navigate(APP_CREATE_USER);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                            {users != null && <MUIDataTable title={"Merchant list"} data={MerchantData} columns={columns} options={options} />}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>
            </Card>
        </DashboardLayout>
    )
}
