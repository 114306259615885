import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import JoditEditor from 'jodit-react';
import { useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContent } from "api/services/contentService";
import { APP_CONTENT } from "constants/AppRouteName";


export default function CreateContent() {
    const editor = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleCreate = async () => {
        let data = JSON.stringify({
            title: title, content: content
        });
        dispatch(createContent(data, APP_CONTENT, navigate));

    }
    
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Add Content
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter Content details to create
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Title" variant="standard" onChange={(e) => setTitle(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={2}>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1} // tabIndex of textarea
                                        onChange={newContent => setContent(newContent)}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleCreate}>
                                    Create
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
