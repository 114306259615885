import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect } from "react";
import { initialState } from "app/initialState";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, updateCategory } from "api/services/categoryService";
import { APP_RATE_OF_INTEREST } from "constants/AppRouteName";
import { Formik, Form, ErrorMessage } from "formik";
import { RateOfInterestSchema } from "app/validationSchema";
import NativeSelect from '@mui/material/NativeSelect';

export default function EditRateofInterest() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { categories } = useSelector((state) => state.appStore);
  

    let category = categories.filter((item)=>{return item.id === Number(id)})

    let name = category[0].name ; 
    let GeneralRateOfInterest = category[0].GeneralRateOfInterest ;
    let HighRiskRateOfInterest = category[0].HighRiskRateOfInterest ;
   

    const handleUpdate = async (formValues) => {
        const { GeneralRateOfInterest, HighRiskRateOfInterest } = formValues;
      
        const dataToUpdate = {
            name : category[0].name , 
            id :  id , 
            GeneralRateOfInterest :GeneralRateOfInterest , 
            HighRiskRateOfInterest :HighRiskRateOfInterest, 
            description : category[0].description

        }
       
        dispatch(updateCategory(dataToUpdate, APP_RATE_OF_INTEREST, navigate));
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Rate Of Interest
                    </MDTypography>
                    
                </MDBox>

                <Formik 
                 initialValues={{
                    GeneralRateOfInterest: GeneralRateOfInterest || '',
                    HighRiskRateOfInterest: HighRiskRateOfInterest || '',
                }}
                validationSchema={RateOfInterestSchema}
                onSubmit={(values) => handleUpdate(values)}
                >
                    {(formik) => {
              const {
                values,
                handleChange,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty
              } = formik;
              return (
                   <Form>

                <MDBox pt={4} pb={3} px={3}>
                    
                    <MDBox>

                        <Grid container spacing={3}>

                        <Grid item xs={12} md={6} lg={6}>
                                                <MDBox mb={2} mt={1.5}>
                                                    <NativeSelect
                                                        id="productcategory"
                                                        name="productcategory"
                                                        defaultValue={name}
                                                        fullWidth
                                                    
                                                    >
                                                        <option value={name}>{name}</option>
                                                       
                                                    </NativeSelect>
                                                 
                                                </MDBox>
                                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" name="GeneralRateOfInterest" label="General Rate Of Interest" variant="standard" value={values.GeneralRateOfInterest} fullWidth onChange={handleChange} onBlur={handleBlur}
                          className={errors.GeneralRateOfInterest && touched.GeneralRateOfInterest ?
                            "input-error" : "null"} />
                             <ErrorMessage name="GeneralRateOfInterest" component="span" className="error" />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" name="HighRiskRateOfInterest" label="HighRisk Rate Of Interest" variant="standard" value={values.HighRiskRateOfInterest} fullWidth onChange={handleChange}  onBlur={handleBlur}
                          className={errors.HighRiskRateOfInterest && touched.HighRiskRateOfInterest ?
                            "input-error" : "null"}/>
                                </MDBox>
                                <ErrorMessage name="HighRiskRateOfInterest" component="span" className="error" />
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info"  type="submit"  className={dirty && isValid ? "" : "disabled-btn"}
                        disabled={!(dirty && isValid)}>
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                    
                </MDBox>


                  </Form>
                  );
                }}
                </Formik>


            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
