import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { useDispatch, useSelector } from "react-redux";


import { CONFIRM_DELETE_MERCHANT_TYPE_MANAGER } from "constants/ConfirmMessage";
import ConfirmBox from "../general/ConfirmBox";
import { getMerchantTypes, deleteMerchantType } from "api/services/merchantTypeService";

export default function MerchantTypeManagement() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const { merchantTypes } = useSelector((state) => state.appStore);
    const muiCache = createCache({
        "key": "mui",
        "prepend": true
    });

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FF0000"
                    }
                }
            }
        }
    })

    const columns = [

        {
            name: "id",
            label: "ID",
            accessor: "id",
            options: {
                filter: false,
                sort: false,
                empty: false,
                "display": false,
                "viewColumns": false
            }
        },
        {
            name: "name",
            label: "NAME",
            accessor: "name",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "Actions",
            label: "ACTIONS",
            accessor: "actions",
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Link to={`/merchant-type/edit/${tableMeta.rowData[0]}`}>
                                <MDButton color="info" size="small">
                                    Edit
                                </MDButton>
                            </Link>
                            <MDButton color="error" size="small" onClick={() => {
                                handleDelete(tableMeta.rowData[0])
                            }}>
                                Delete
                            </MDButton>

                        </>
                    );
                }
            }
        }
    ]
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none"
    };
    
    useEffect(async () => {
        dispatch(getMerchantTypes());
    }, []);

    const handleDelete = (id) => {
        setId(id);
        setOpen(true);
    }
    const handleOption = async () => {
        setOpen(false);

        dispatch(deleteMerchantType({ id }));
        dispatch(getMerchantTypes());
    }
    const handleCreate = async () => {
        navigate("/create-merchanttype");
    }
   
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
                <MDBox align="right" marginRight="5%" marginTop="1%" marginBottom="1%">
                    <MDButton align="right" color="info" size="small" onClick={handleCreate}>Create Merchant Type</MDButton>
                </MDBox>
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                        { merchantTypes != null && <MUIDataTable title={"Merchant Type list"} data={merchantTypes} columns={columns} options={options} />}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>

            </Card>
            <ConfirmBox
                open={open}
                setOpen={setOpen}
                message={CONFIRM_DELETE_MERCHANT_TYPE_MANAGER}
                handleOption={handleOption}
            />
        </DashboardLayout>
    )
}
