import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setContents,
    setContent
} from "app/appSlice";
import { ROLES } from "utils/dataList";
import * as API from "constants/ApiEndpoint";

export const getContents = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_CONTENTS);
        if (response.data.status_code == 200) {
            response.data.data.forEach((data) => {
                data["role"] = ROLES[data.role] ?? ROLES[0];
            })
            dispatch(setContents(response.data.data));
        }
        else {
            dispatch(setContents([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getContent = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_CONTENT({ id: req.id }));

        if (response.data.status_code == 200) {

            let resultData = {
                title: response.data.data.title,
                id: response.data.data.id,
                staticcontent: response.data.data.content
            }
            dispatch(setContent(resultData));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateContent = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.UPDATE_CONTENT, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const createContent = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.CREATE_CONTENT, req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message  }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}