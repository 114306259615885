import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { INSTALLMENT_MANAGER } from "constants/AppRouteName";
import { getSingleCustomersInstallmentList, updateInstallmentStatus } from '../../api/services/installmentService'
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from "dayjs";
import MDTypography from "components/MDTypography";
import { updateToastAgent } from "app/appSlice";

export default function CustomerInstallment() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [dateValues, setDateValues] = useState([]);
    const { SingleCustomerInstallmentsList, CustomerInstallmentsList , data , todaysInstallmentList } = useSelector((state) => state.appStore);
    const [userData , setUserData] = useState(data)

    // console.log(SingleCustomerInstallmentsList)

   
  
    const muiCache = createCache({
        key: "mui",
        prepend: true,
    });

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: "#FF0000",
                        },
                    },
                },
            },
        });

    const handleDateChangeForRow = (index, date) => {
        const newDateValues = [...dateValues];
        newDateValues[index] = date || null;
        setDateValues(newDateValues);
    };

    const submitEMI = (data, rowIndex, tableMeta) => {
        const selectedDate = dateValues[rowIndex];
        const formattedDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
        let EmiData = {
            id: data[0],
            payment_date: formattedDate,
            payment_status: true , 
            updated_by : userData.id
        };
    
        for (let i = rowIndex - 1; i >= 0; i--) {
            const previousRowData = tableMeta.currentTableData[i].data;
            const previousPaymentStatus = previousRowData[4]; 
    
            if (!previousPaymentStatus) {
                dispatch(updateToastAgent({ t: "e", m: "Please mark the previous payment first." }));
                return; 
            }
        }
    
        if (selectedDate) {
            const EmiRecord = SingleCustomerInstallmentsList.filter((item)=>{return item.payment_status===false})
            let loan_invoice_id = EmiRecord[0].loan_invoice_id ;
            EmiData.loan_invoice_id = loan_invoice_id
            if(EmiRecord.length===1){
               EmiData.last_Emi = true
            }


            // updateInstallmentStatus(dispatch, EmiData);
            // setTimeout(() => { getSingleCustomersInstallmentList(dispatch, location.pathname.split('/')[3]); }, 5000);

            const updateStatusAndRefreshList = (EmiData) => {
                return new Promise((resolve, reject) => {
                    updateInstallmentStatus(dispatch, EmiData)
                        .then(() => {
                            const customerId = location.pathname.split('/')[3];
                            return getSingleCustomersInstallmentList(dispatch, customerId);
                        })
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            };
            
          
            updateStatusAndRefreshList(EmiData)
              
            
        } else {
            dispatch(updateToastAgent({ t: "e", m: "Please select a date." }));
        }
    };
    

    const columns = [
        {
            name: "id",
            label: "Id",
            accessor: "id",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: true,
                viewColumns: true,
            },
        },
        {
            name: "due_date",
            label: "Due Date",
            accessor: "due_date",
            options: {
                filter: true,
                sort: true,
                empty: true,
                display: true,
                viewColumns: true,
            },
        },
        {
            name: "payment_date",
            label: "Payment Date",
            accessor: "payment_date",
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowIndex = tableMeta.rowIndex;
                    const selectedDate = dateValues[rowIndex];
                    // console.log(tableMeta.rowData)
                    return (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {tableMeta.rowData[4] === false ? <DatePicker
                                value={selectedDate}
                                onChange={(date) => handleDateChangeForRow(rowIndex, date)}
                                label="Select Date"
                                inputFormat="yyyy/MM/dd"
                            /> : <p>{tableMeta.rowData[2]}</p>}
                        </LocalizationProvider>
                    );
                },
            },
        },
        {
            name: "payment_amount",
            label: "Payment Amount",
            accessor: "payment_amount",
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const Amount = Number(tableMeta.rowData[3]).toLocaleString();
                   
                    return <p>{Amount}</p>;
                } , 
                setCellProps: () => ({
                    style: {
                      textAlign: 'left',
                      paddingLeft: '100px',
                    },
                  }),
            },
        },
        {
            name: "payment_status",
            label: "Payment Status",
            accessor: "payment_status",
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    // console.log(tableMeta.rowData[4])
                    const rowIndex = tableMeta.rowIndex;
                    return (
                        <>
                            {tableMeta.rowData[4] === false ? <input type="checkbox" onChange={() => { submitEMI(tableMeta.rowData, rowIndex , tableMeta) }} /> : <input type="checkbox" checked />}
                        </>
                    );
                },
            },
        },
    ];

    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none",
    };

    useEffect(() => {
        getSingleCustomersInstallmentList(dispatch, location.pathname.split('/')[3]);
    }, []);

    const customerData = todaysInstallmentList.find(item => item.loan_invoice_id === Number(location.pathname.split('/')[3])) 
    ? todaysInstallmentList.filter(item => item.loan_invoice_id === Number(location.pathname.split('/')[3]))
    : CustomerInstallmentsList.filter(item => item.loan_invoice_id === Number(location.pathname.split('/')[3]));


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Manage Installments
                    </MDTypography>

                </MDBox>
                <MDBox align="right" marginRight="3%" marginTop="1%" marginBottom="1%" marginLeft="3%"  >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6} sx={{ p: 2 }}>
                            <MDInput type="text" name="Customer Name" label="Customer Name" variant="standard" value={customerData[0]['CustomerDetail.name']} fullWidth disabled />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{ p: 2 }}>
                            <MDInput type="text" name="Loan Amount" label="Loan Amount" variant="standard" value={customerData[0]['LoanDetail.amount']} fullWidth disabled />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{ p: 2 }}>
                            <MDInput type="text" name="Number of Installments" label="Number of Installments" variant="standard" value={customerData[0]['LoanDetail.installments']} fullWidth disabled />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} sx={{ p: 2 }}>
                            <MDInput type="text" name="Status" label="Status" variant="standard" value={customerData[0].payment_status === false ? 'Active' : 'Closed'} fullWidth disabled />
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                            {SingleCustomerInstallmentsList != null && (
                                <MUIDataTable
                                    title={"Customers Installment"}
                                    data={SingleCustomerInstallmentsList}
                                    columns={columns}
                                    options={options}
                                />
                            )}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>
            </Card>
        </DashboardLayout>
    );
}
