// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
// import { TextField } from "@material-ui/core";
import NativeSelect from "@mui/material/NativeSelect";

import { useDispatch, useSelector } from "react-redux";
import { getMerchantTypes } from "api/services/merchantTypeService";
import { createUser } from "api/services/userService";
import { APP_USER_LIST } from "constants/AppRouteName";
import { Formik, Form, ErrorMessage } from "formik";
import { initialState } from "app/initialState";
import { CreateUserSchema } from "app/validationSchema";
import { getUsers } from "api/services/userService";
import { getCategories } from "api/services/categoryService";
import CountryList from "../../utils/countryList";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment';


function Cover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LO, setLO] = useState(false);
  const { merchantTypes, users, categories } = useSelector((state) => state.appStore);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getMerchantTypes());
    dispatch(getCategories());
    return () => { };
  }, []);

  useEffect(() => {
    if (users?.length) {
      setLO(users.some((el) => el.role === "LO"));
    }
  }, [users]);

  const submitForm = ({
    email,
    password,
    first_name,
    last_name,
    role,
    confirm_password,
    merchant_type,
    registered_phone_number,
    alternate_phone_number,
    address,
    deduct_percent,
    registration_number
    
  }) => {
   let country_code = '+357'
    dispatch(
      createUser(
        JSON.stringify({
          email,
          password,
          first_name,
          last_name,
          role,
          confirm_password,
          merchant_type,
          registered_phone_number,
          alternate_phone_number,
          address,
          deduct_percent,
          country_code , 
          registration_number
        }),
        APP_USER_LIST,
        navigate
      )
    );
  };

  return (
    // <CoverLayout image={bgImage}>
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create User
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter user details to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialState.createUser}
            validationSchema={CreateUserSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const { values, handleChange, errors, touched, handleBlur, isValid, dirty } = formik;
              const selectedCategoryIds = Array.isArray(values.deduct_percent)
                ? values.deduct_percent.map((item) => Number(item.category_id))
                : [];
              console.log(selectedCategoryIds);

              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mt={1.5}>
                        <NativeSelect
                          id="role"
                          name="role"
                          defaultValue=""
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.role && touched.role ? "input-error" : null}
                        >
                          <option value="">Select Role</option>
                          <option value={1}>Admin</option>
                          <option value={2}>Merchant</option>
                        </NativeSelect>
                        <ErrorMessage name="role" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          id="email"
                          name="email"
                          label="Email"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.email && touched.email ? "input-error" : "null"}
                        />
                      </MDBox>
                      <ErrorMessage name="email" component="span" className="error" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          id="first_name"
                          name="first_name"
                          label="First Name"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.first_name && touched.first_name ? "input-error" : "null"
                          }
                        />
                        <ErrorMessage name="first_name" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          id="last_name"
                          name="last_name"
                          label="Last Name"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.last_name && touched.last_name ? "input-error" : "null"}
                        />
                        <ErrorMessage name="last_name" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type={passwordShown ? "text" : "password"}
                          id="password"
                          name="password"
                          label="Password"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.password && touched.password ? "input-error" : null}
                        />
                        <button type="button" onClick={togglePassword}>
                          {passwordShown ? (
                            <Icon color="info" title="Show" fontSize="small">
                              visibility_off
                            </Icon>
                          ) : (
                            <Icon color="info" title="Show" fontSize="small">
                              visibility
                            </Icon>
                          )}
                        </button>
                      </MDBox>
                      <ErrorMessage name="password" component="span" className="error" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          label="Confirm Password"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.confirm_password && touched.confirm_password
                              ? "input-error"
                              : null
                          }
                        />
                        <ErrorMessage name="confirm_password" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mt={2}>
                        {values?.role == 2 && (
                          <>
                            <TextField
                              name="address"
                              placeholder="Address"
                              variant="standard"
                              multiline
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.address && touched.address ? "input-error" : "null"}
                            />
                          </>
                        )}
                        <ErrorMessage name="address" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mt={1.6}>
                        {values?.role == 2 && (
                          <>
                            <NativeSelect
                              id="merchant_type"
                              name="merchant_type"
                              defaultValue={values.merchantTypes}
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.merchant_type && touched.merchant_type ? "input-error" : null
                              }
                            >
                              <option value="" disabled selected>
                                Select Merchant Type
                              </option>
                              {merchantTypes
                                ? merchantTypes.map((ty, i) => {
                                  return (
                                    <option key={i} value={ty.id}>
                                      {ty.name}
                                    </option>
                                  );
                                })
                                : ""}
                            </NativeSelect>
                          </>
                        )}
                        <ErrorMessage name="merchant_type" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    {values?.role == 2 && (
                      <>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mt={2}>
                            <NativeSelect
                              id={`product_category_0`}
                              name={`deduct_percent[0].category_id`}
                              value={values.deduct_percent[0]?.category_id || ""}
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors?.deduct_percent?.[0]?.category_id &&
                                  touched?.deduct_percent?.[0]?.category_id
                                  ? "input-error"
                                  : null
                              }
                            >
                              <option value="" disabled>
                                Select Product Category
                              </option>
                              {categories.map((ty, i) => (
                                <option
                                  key={i}
                                  value={ty.id}
                                  disabled={selectedCategoryIds.includes(ty.id)}
                                >
                                  {ty.name}
                                </option>
                              ))}
                            </NativeSelect>
                            <ErrorMessage
                              name={`deduct_percent[0].category_id`}
                              component="span"
                              className="error"
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={2}>
                            <MDInput
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id={`deduct_percent_0`}
                              name={`deduct_percent[0].deduct_percent`}
                              label="Deduct Percent"
                              variant="standard"
                              fullWidth
                              value={values.deduct_percent[0]?.deduct_percent || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors?.deduct_percent?.[0]?.deduct_percent &&
                                  touched?.deduct_percent?.[0]?.deduct_percent
                                  ? "input-error"
                                  : null
                              }
                            />
                            <ErrorMessage
                              name={`deduct_percent[0].deduct_percent`}
                              component="span"
                              className="error"
                            />
                          </MDBox>
                        </Grid>
                      </>
                    )}


                    
                    

                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        {values?.role == 2 && (
                          <>
                            <MDInput
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="registered_phone_number"
                              name="registered_phone_number"
                              label="Registered Phone Number(+357)"
                              variant="standard"
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.registered_phone_number && touched.registered_phone_number
                                  ? "input-error"
                                  : "null"
                              }
                            />
                          </>
                        )}
                        <ErrorMessage
                          name="registered_phone_number"
                          component="span"
                          className="error"
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        {values?.role == 2 && (
                          <>
                            <MDInput
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="alternate_phone_number"
                              name="alternate_phone_number"
                              label="Alternate Phone Number"
                              variant="standard"
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.alternate_phone_number && touched.alternate_phone_number
                                  ? "input-error"
                                  : "null"
                              }
                             
                            />
                          </>
                        )}
                        <ErrorMessage
                          name="alternate_phone_number"
                          component="span"
                          className="error"
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mt={2}>
                        {values?.role == 2 && (
                          <>
                            <TextField
                              name="registration_number"
                              placeholder="Merchant Registration Number"
                              variant="standard"
                              multiline
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.registration_number && touched.registration_number ? "input-error" : "null"}
                            />
                          </>
                        )}
                        <ErrorMessage name="registration_number" component="span" className="error" />
                      </MDBox>
                    </Grid>

                    <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        className={dirty && isValid ? "" : "disabled-btn"}
                        disabled={!(dirty && isValid)}
                      >
                        Create
                      </MDButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>
    // {/* </CoverLayout> */}
  );
}

export default Cover;
