import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "app/initialState";
import { getAppUser } from "api/services/appUserService";
import { Link } from "react-router-dom";
import { APP_IBAN_DETAILS, APP_FINCLUDE_DETAILS } from "constants/AppRouteName";

export default function ViewUser() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { customer } = useSelector((state) => state.appStore);
  
  let {
    first_name,
    last_name,
    email,
    dob,
    nationality,
    marital_status,
    id_number,
    expiry,
    phone,
    payslip,
    address_image,
    profile,
    document,
    status,
    role,
    iban,
    bban,
    affordability
  } = customer ?? initialState.customer;



 const IMAGE_URL = "https://app.gostatim.com/documents/"

  useEffect(() => {
    dispatch(getAppUser({ id }));
  }, [id]);

  return (
    // <CoverLayout image={bgImage}>
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            User
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            User details
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="First Name"
                    name="first_name"
                    value={first_name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Last Name"
                    name="last_name"
                    value={last_name}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    value={email}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Date of Birth"
                    name="dob"
                    value={dob}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nationality"
                    name="nationality"
                    value={nationality}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Marital Status"
                    name="marital_status"
                    value={marital_status}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Id Number"
                    name="id_number"
                    value={id_number}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Expiry"
                    name="expiry"
                    value={expiry}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Phone"
                    name="phone"
                    value={phone}
                    variant="standard"
                    readOnly={true}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            textAlign="center"
          >
            <MDTypography display="block" variant="button" color="white" my={1}>
              User Doc
            </MDTypography>
          </MDBox>

          <MDBox mx={5} style={{ display: 'flex' }}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={2}>
                <h4>Country ID</h4>

                <a
                  href={`${IMAGE_URL}${document}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${IMAGE_URL}${document}`}
                    srcSet={`${IMAGE_URL}${document}`}
                    alt={`${document}`}
                    loading="lazy"
                    height="200"
                    width="200"
                  />
                </a>

              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={2}>
                <h4>Selfie</h4>

                <a
                  href={`${IMAGE_URL}${profile}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${IMAGE_URL}${profile}`}
                    srcSet={`${IMAGE_URL}${profile}`}
                    alt={`${profile}`}
                    loading="lazy"
                    height="200"
                    width="200"
                  />
                </a>

              </MDBox>
            </Grid>
          </MDBox>



          {(affordability || payslip) && (
            <>

              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                textAlign="center"
              >
                <MDTypography display="block" variant="button" color="white" my={4}>
                  Assesment Status
                </MDTypography>
              </MDBox>

              <MDBox style={{ display: 'flex' }}>
                <Grid item xs={12} md={6} lg={6} mx={5}>
                  <MDBox mb={2}>
                    <h4>IBAN Status</h4>
                    {iban ? <Link to={APP_IBAN_DETAILS}>View Details</Link> : <p>No Details Found</p>}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Finclude Status</h4>
                    {affordability ? <Link to={APP_FINCLUDE_DETAILS}>View Details</Link> : <p>No Details Found</p>}
                  </MDBox>
                </Grid>
              </MDBox>

              <MDBox style={{ display: 'flex' }} >



                <Grid item xs={12} md={6} lg={6} mx={5}>
                  <MDBox mb={2}>
                    <h4>Utility Bill</h4>
                    {affordability ? (
                      <p>Not Required</p>
                    ) : address_image ? (
                      <a
                        href={`${IMAGE_URL}${address_image}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`${IMAGE_URL}${address_image}`}
                          srcSet={`${IMAGE_URL}${address_image}`}
                          alt={address_image}
                          loading="lazy"
                          height="200"
                          width="200"
                        />
                      </a>
                    ) : (
                      <p>Not Provided</p>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={2}>
                    <h4>Payslip</h4>
                    {affordability ? (
                      <p>Not Required</p>
                    ) : payslip ? (
                      <a
                        href={`${IMAGE_URL}${payslip}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`${IMAGE_URL}${payslip}`}
                          srcSet={`${IMAGE_URL}${payslip}`}
                          alt={payslip}
                          loading="lazy"
                          height="200"
                          width="200"
                        />
                      </a>
                    ) : (
                      <p>Not Provided</p>
                    )}
                  </MDBox>
                </Grid>


              </MDBox>
            </>
          )}



        </MDBox>
      </Card>
    </DashboardLayout>
    // </CoverLayout>
  );
}
