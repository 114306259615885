import axios from "axios";

const BASE_URL = "https://app.gostatim.com/api";
// const BASE_URL = "http://localhost:3000/api";

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});



const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'token': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
    },
    //withCredentials: true
});

// Add a request interceptor
axiosPrivate.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosPrivate.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response } = error;
    if (response?.status == 403) {
        localStorage.clear();
        window.location = "/logout";
    }
    return Promise.reject(error);
});

export { axiosPrivate };