import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useRef,useState } from "react";
import JoditEditor from 'jodit-react';
import { setContent } from "app/appSlice";
import { initialState } from "app/initialState";
import { useDispatch, useSelector } from "react-redux";
import { getContent, updateContent } from "api/services/contentService";
import { APP_CONTENT } from "constants/AppRouteName";

export default function EditContent() {
    const editor = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { content } = useSelector((state) => state.appStore);

    const { title, staticcontent } = content ?? initialState.content;

    const onInputChange = e => {
        if(e.target){
            dispatch(setContent({ ...content, [e.target.name]: e.target.value }));
        }else{
            dispatch(setContent({ ...content, ['content']: e }));
        }
        
    }

    useEffect(() => {
        dispatch(getContent({ id }));
    }, [id]);
    //

    const handleUpdate = async () => {
        dispatch(updateContent(content, APP_CONTENT, navigate));
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Content
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter Content details
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" name="title" label="tItle" variant="standard" value={title} fullWidth onChange={e => onInputChange(e)} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={2}>
                                    <JoditEditor
                                        ref={editor}
                                        value={staticcontent}
                                        tabIndex={1} // tabIndex of textarea
                                        onChange={e => onInputChange(e)}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleUpdate}>
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
