import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";

import { CONFIRM_STATUS_CHANGE_USER } from "constants/ConfirmMessage";
import ConfirmBox from "../general/ConfirmBox";

import createCache from "@emotion/cache";
import { getAppUsers, changeStatus } from "api/services/appUserService";
import { APP_USER_VIEW } from "constants/AppRouteName";

export default function UserList() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const { data: user, customers } = useSelector((state) => state.appStore);
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

 

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "id",
      label: "CLIENT ID",
      accessor: "id",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),
      },
    },
    {
      name: "name",
      label: "CLIENT NAME",
      accessor: "name",
      options: {
        filter: true,
        sort: true,
        empty: true,
      },
    },
    {
      name: "email",
      label: "LOGIN ID",
      accessor: "email",
      options: {
        filter: true,
        sort: true,
        empty: true,
      },
    },
    {
      name: "created_at",
      label: "REGISTRATION DATE",
      accessor: "created_at",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{format(new Date(tableMeta.rowData[3]), "yyyy-MM-dd")}</>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),
      },
    },
    {
      name: "assessment_status",
      label: "ASSESMENT STATUS",
      accessor: "assessment_status",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          return <>{
            tableMeta.rowData[4] == 2 ? "Manual" :
              tableMeta.rowData[4] == 1 ? "Finclude" :
                "Registered"
          }</>;
        },
      },
    },
    {
      name: "status",
      label: "ACTIONS",
      accessor: "status",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         
          return (
            <>
              {/* <Link to={`${APP_APP_USER_LIST}/${tableMeta.rowData[0]}`}>
                                <MDButton color="info" size="small">
                                    Edit
                                </MDButton>
                            </Link> */}
              <Link to={`${APP_USER_VIEW}/${tableMeta.rowData[0]}`}>
                <MDButton color="info" size="small">
                  View
                </MDButton>
              </Link>
              <MDButton
                color="error"
                size="small"
                onClick={() => {
                  handleStatus(tableMeta.rowData[0], tableMeta.rowData[5]);
                }}
              >
                Status
              </MDButton>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
  };
  useEffect(async () => {
    dispatch(getAppUsers());
  }, []);

  const handleStatus = (id, status) => {
    setId(id);
    setStatus(status);
    setOpen(true);
  };

  const handleOption = async () => {
    setOpen(false);

    dispatch(changeStatus({ id, status }));
    dispatch(getAppUsers());
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {customers != null && (
                <MUIDataTable
                  title={"App Users"}
                  data={customers}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        message={CONFIRM_STATUS_CHANGE_USER}
        handleOption={handleOption}
      />
    </DashboardLayout>
  );
}
