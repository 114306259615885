import { updateToastAgent } from "app/appSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toaster = () => {

    const dispatch = useDispatch();
    const { toastAgent } = useSelector((state) => state.appStore);

    const [timeOut, setTimeOut] = useState(5000);

    useEffect(() => {
        if (toastAgent) {
            if (toastAgent?.t == "s") {
                toast.success(toastAgent?.m);
            } else if (toastAgent?.t == "e") {
                toast.error(toastAgent?.m);
            }
            setTimeout(() => {
                dispatch(updateToastAgent(null));
            }, timeOut);

            if (toastAgent?.time) {
                setTimeOut(toastAgent?.time);
            }            
        }
        return () => { };
    }, [toastAgent]);


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={timeOut}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default Toaster;
