
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect, { SelectChangeEvent } from '@mui/material/NativeSelect';

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "app/appSlice";
import { getAppUser, updateUser } from "api/services/appUserService";
import { initialState } from "app/initialState";
import { APP_APP_USER_LIST } from "constants/AppRouteName";

export default function EditUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useSelector((state) => state.appStore);
    const { name, email } = user ?? initialState.user;

    const onInputChange = e => {
        dispatch(setUser({ ...user, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        dispatch(getAppUser({ id }));
    }, [id]);

    const handleUpdate = async () => {
        dispatch(updateUser(JSON.stringify(user), APP_APP_USER_LIST, navigate));
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit User
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter User details to Edit
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Name" name="name" value={name} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="email" label="Email" name="email" value={email} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleUpdate}>
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
