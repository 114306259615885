import { axiosPrivate } from "api/axios";
import { setCredits } from "app/appSlice";
import {
    updateLoader,
    updateToastAgent,
    setLoans,
    setLoan , 
    setOnGoingLoans , 
    setNewLoans , 
    setApplliedLoans , 
    setCompletedLoans
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getLoans = (req) => async (dispatch) => {
    
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_LOANS);
        // console.log(response)
        if (response.data.status_code == 200) {
            dispatch(setLoans(response.data.data));
        } else {
            dispatch(setLoans([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}



export const getLoan = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_LOAN({ id: req.id }));
        console.log(response)
        if (response.data.status_code == 200) {
            let productData = {
                id: response.data.data.id,
                employment_status: response.data.data.name,
                barcode: response.data.data.barcode,
                description: response.data.data.description,
                category: response.data.data.category,
                price: response.data.data.price,
                color: response.data.data.color,
                size: response.data.data.size,
            }
            dispatch(setLoan(response.data.data));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const changeStatus = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.LOAN_CHANGE_STATUS({ id: req.id, status: req.status }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }   
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}



export const downloadDocument = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.LOAN_DOWNLOAD_DOCUMENT({ id: req.id }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getNewLoans =  async (dispatch) => {
    
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_NEW_LOANS);
        console.log(response)
        if (response.data.status_code == 200) {
            const flattenedArray = flattenArrayOfObjects(response.data.data);
            dispatch(setNewLoans(flattenedArray));
        } else {
            dispatch(setNewLoans ([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const getongoingLoans = async (dispatch) => {
  
    
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_ONGOING_LOANS);
        // console.log(response)
        if (response.data.status_code == 200) {
            const flattenedArray = flattenArrayOfObjects(response.data.data);
            dispatch(setOnGoingLoans(flattenedArray));
        } else {
            dispatch(setOnGoingLoans ([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getAppliedLoans = async (dispatch) => {
  
    
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_APPLIED_LOANS);
        // console.log(response)
        if (response.data.status_code == 200) {
            const flattenedArray = flattenArrayOfObjects(response.data.data);
            // console.log(flattenedArray)
            dispatch(setApplliedLoans(flattenedArray));
        } else {
            dispatch(setApplliedLoans ([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(setApplliedLoans ([]));
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const acceptAppliedLoan =  async (dispatch , req) => {
    
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.UPDATE_APPLIED_LOAN_STATUS , req);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getCompletedLoans = async (dispatch) => {
  
    
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_COMPLETED_LOAN_STATUS);
        // console.log(response)
        if (response.data.status_code == 200 && response.data.message != "No data found") {
            const flattenedArray = flattenArrayOfObjects(response.data.data);
            // console.log(flattenedArray)
            dispatch(setCompletedLoans(flattenedArray));
        } else {
            dispatch(setCompletedLoans ([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(setCompletedLoans ([]));
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}



 export function flattenArrayOfObjects(arr) {
    return arr.map(obj => flattenObject(obj));
}

function flattenObject(obj, parentKey = '') {
    return Object.keys(obj).reduce((acc, key) => {
        const prefixedKey = parentKey ? `${parentKey}.${key}` : key;
        if (Array.isArray(obj[key])) {
            const flattenedArray = flattenArrayOfObjects(obj[key]);
            flattenedArray.forEach((item, index) => {
                Object.keys(item).forEach(subKey => {
                    acc[`${prefixedKey}[${index}].${subKey}`] = item[subKey];
                });
            });
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            const flattenedChild = flattenObject(obj[key], prefixedKey);
            Object.assign(acc, flattenedChild);
        } else {
            acc[prefixedKey] = obj[key];
        }
        return acc;
    }, {});
}