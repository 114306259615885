import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setrevenueReportData
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";



export const revenueReport = (req) => async (dispatch) => {
    try {
        
        dispatch(updateLoader(true));
        let results = await axiosPrivate.post(API.GET_REVENUE_REPORT, req);
        if (results.data.status_code == 200) {
            let data = flattenObjects(results.data.data)
            dispatch(setrevenueReportData(data))
            
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }));
           
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

function flattenObjects(arr) {
    const result = [];

    function flatten(obj) {
        const flatObj = {};

        function innerFlatten(innerObj, prefix = '') {
            for (const key in innerObj) {
                if (typeof innerObj[key] === 'object' && innerObj[key] !== null) {
                    innerFlatten(innerObj[key], `${prefix}${key}.`);
                } else {
                    flatObj[`${prefix}${key}`] = innerObj[key];
                }
            }
        }

        innerFlatten(obj);
        result.push(flatObj);
    }

    arr.forEach(obj => flatten(obj));

    return result;
}


