
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect, { SelectChangeEvent } from '@mui/material/NativeSelect';

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { initialState } from "app/initialState";
import { setProduct } from "app/appSlice";

import { getCategories } from "api/services/categoryService";
import { getProduct } from "api/services/productService";
import { APP_PRODUCT_LIST } from "constants/AppRouteName";
import validator from "validator";
import { updateToastAgent } from "app/appSlice";
import { updateProduct } from "api/services/productService";

export default function EditProduct() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [image, setImage] = useState('');

    const { product, categories } = useSelector((state) => state.appStore);

    const { name, barcode, description, category, price, color, size } = product ?? initialState.product;

    
    const selectFile = ((e) => {
        setImage(e.target.files[0])
    })
    //

    const onInputChange = e => {
        dispatch(setProduct({ ...product, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getProduct({ id }));
    }, [id]);

    const handleUpdate = async () => {
        if (validator.isEmpty(name)) {
            dispatch(updateToastAgent({ t: "e", m: "Name is required." }));
        } else if (validator.isEmpty(barcode)) {
            dispatch(updateToastAgent({ t: "e", m: "Barcode is required." }));
        } else if (validator.isEmpty(description)) {
            dispatch(updateToastAgent({ t: "e", m: "Description is required." }));
        } else if (validator.isEmpty(category+"")) {
            dispatch(updateToastAgent({ t: "e", m: "Category is required." }));
        } else if (validator.isEmpty(price+"")) {
            dispatch(updateToastAgent({ t: "e", m: "Price is required." }));
        }else {
            
            let data = new FormData();
            
            data.append('image', image);
            data.append('id', id);
            data.append('name', product.name);
            data.append('barcode', product.barcode);
            data.append('description', product.description);
            data.append('category', product.category);
            data.append('price', product.price);
            data.append('color', product.color);
            data.append('size', product.size)
            dispatch(updateProduct(data, APP_PRODUCT_LIST, navigate));
        }
    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Product
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter product details to Edit
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Name" name="name" value={name} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Barcode" name="barcode" value={barcode} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Description" name="description" value={description} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <NativeSelect value={category} name="category" onChange={(e) => onInputChange(e)} fullWidth>
                                        <option value="">Select Category</option>
                                        {categories ? categories.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        }) : false}
                                    </NativeSelect>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="number" label="Price" name="price" value={price} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Color" name="color" value={color} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="size" label="Size" name="size" value={size} variant="standard" onChange={(e) => onInputChange(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="file" label="Product Image" variant="standard" onChange={(e) => selectFile(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleUpdate}>
                                    Update
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
