import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getUsers, getUser } from "api/services/userService";
import { APP_CREATE_USER } from "constants/AppRouteName";

export default function UserList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: user, users } = useSelector((state) => state.appStore);
    const muiCache = createCache({
        "key": "mui",
        "prepend": true
    });
    const [superAdmin] = useState(user ? user?.is_super_admin : null);
    // console.log(users)

    let userData = users ? users.filter((item)=>{return item.role!=='ADMIN'}) : []

    async function setUser(id){
            await dispatch(getUser({ id }));
            navigate(`/user/edit/${id}`)
            
    }

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FF0000"
                    }
                }
            }
        }
    })

    const columns = [
        {
            name: "id",
            label: "ID",
            accessor: "id",
            options: {
                filter: true,
                sort: true,
                empty: true,
                "display": false,
                "viewColumns": false
            }
        },
        {
            name: "name",
            label: "USER NAME",
            accessor: "name",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "email",
            label: "LOGIN ID",
            accessor: "email",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "role",
            label: "ROLE",
            accessor: "role",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        {
            name: "Actions",
            label: "ACTIONS",
            accessor: "actions",
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                       
                        <>
                            
                                <MDButton color="info" size="small" onClick={() => {setUser(tableMeta.rowData[0])}}>
                                    Edit
                                </MDButton>
                        
                            { superAdmin == 1 ?
                            <Link to={`/user/change-password/${tableMeta.rowData[0]}`}>
                                <MDButton color="info" size="small">
                                    Change Password
                                </MDButton>
                            </Link>
                            : false }
                        </>
                    );
                }
            }
        }
    ]
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none"
    };
    useEffect(async () => {
        dispatch(getUsers());
    }, []);

    const handleCreate = async () => {
        navigate(APP_CREATE_USER);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
                <MDBox align="right" marginRight="5%" marginTop="1%" marginBottom="1%">
                    <MDButton align="right" color="info" size="small" onClick={handleCreate}>Create User</MDButton>
                </MDBox>
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                            {users != null && <MUIDataTable title={"User list"} data={superAdmin?users:userData} columns={columns} options={options} />}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>
            </Card>
        </DashboardLayout>
    )
}
