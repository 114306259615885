import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {

    const { loader } = useSelector((state) => state.appStore);

    return (
        <>
            {
                loader ?
                    <div id="loader-container">
                        <div id="loader"></div>
                    </div>
                    : ''
            }
        </>
    );
}
export default Loader;