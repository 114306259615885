
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "api/services/userService";
import { initialState } from "app/initialState";
import { APP_USER_LIST } from "constants/AppRouteName";
import { Formik, Form, ErrorMessage } from "formik";
import { ChangePasswordSchema } from "app/validationSchema";

export default function EditUser() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: user } = useSelector((state) => state.appStore);

    const submitForm = (values) => {
        values.id = id;
        values.old_password = user?.org_password;

        dispatch(changePassword(JSON.stringify(values), APP_USER_LIST, navigate));
    };

    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Change Password
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter Change Password details
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik
                        initialValues={initialState.changePassword}
                        validationSchema={ChangePasswordSchema}
                        onSubmit={submitForm}
                    >
                        {(formik) => {
                            const {
                                values,
                                handleChange,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;

                            return (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <MDBox mb={2}>
                                                <MDInput type="text" id="new_password" name="new_password" label="New Password" variant="standard" fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.old_password && touched.old_password ?
                                                        "input-error" : "null"}
                                                />
                                                <ErrorMessage name="new_password" component="span" className="error" />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <MDBox mb={2}>
                                                <MDInput type="text" id="confirm_password" name="confirm_password" label="Confirm Password" variant="standard" fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className={errors.confirm_password && touched.old_password ?
                                                        "input-error" : "null"}
                                                />
                                                <ErrorMessage name="confirm_password" component="span" className="error" />
                                            </MDBox>
                                        </Grid>
                                        <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                            <MDButton
                                                type="submit"
                                                variant="gradient" color="info"
                                                className={dirty && isValid ? "" : "disabled-btn"}
                                                disabled={!(dirty && isValid)}
                                            >
                                                Submit
                                            </MDButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
