/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// // @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";


import { useSelector, useDispatch } from 'react-redux'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

//Email Validation

import { APP_RESET_PASSOWRD, APP_DASHBOARD } from "constants/AppRouteName";
import { doLogin } from "api/services/authService";
import { updateLoader } from "app/appSlice";
import { Formik, Form, ErrorMessage } from "formik";
import { initialState } from "app/initialState";
import { SignInSchema } from "app/validationSchema";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";


function Basic() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appStore = useSelector((state) => state.appStore);
  const [passwordShown, setPasswordShown] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);


  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (appStore.data) {
      dispatch(updateLoader(true));
      // store local storage
      localStorage.setItem('token', appStore.token);
      // prepare routes
      setTimeout(() => {
        dispatch(updateLoader(false));
        //navigate(APP_DASHBOARD);
        window.location = APP_DASHBOARD;
      }, 1000);
    }
    return () => { };
  }, [appStore.data]);

  const submitForm = ({ email, password }) => {
    dispatch(doLogin({ email, password }));
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in to continue
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialState.signIn}
            validationSchema={SignInSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const {
                //values,
                handleChange,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty
              } = formik;
              return (
                <Form>
                  <MDBox mb={2}>
                    <Input id="email" name="email" type="email" label="Email" placeholder="Email" fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.email && touched.email ?
                        "input-error" : "null"}
                    />
                    <ErrorMessage name="email" component="span" className="error" />
                  </MDBox>
                  <MDBox mb={2}>
                    <Input id="password" name="password"  placeholder="Password" type={passwordShown ? "text" : "password"} label="Password" fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.password && touched.password ?
                        "input-error" : null}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePassword}
                          >
                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <ErrorMessage name="password" component="span" className="error" />
                  </MDBox>
                  <MDBox mt={4} mb={1} display="flex" justifyContent="center">
                    <MDButton
                      type="submit"
                      variant="gradient" color="info"
                      className={dirty && isValid ? "" : "disabled-btn"}
                      disabled={!(dirty && isValid)}>
                      Sign In
                    </MDButton>
                  </MDBox>
                  <MDButton onClick={() => navigate(APP_RESET_PASSOWRD)}>Forgot Password?</MDButton>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
