import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import createCache from "@emotion/cache";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Box } from "@mui/material";
import { DateSchema } from "app/validationSchema";
import { revenueReport } from "api/services/revenueReport";
import Tooltip from "@mui/material/Tooltip";

export default function Revenue() {
  const dispatch = useDispatch();
  const { revenueReportData } = useSelector((state) => state.appStore);
  console.log(revenueReportData);
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "MerchantInfo.name",
      label: "MERCHANT NAME",
      accessor: "MerchantInfo.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "ClientDetail.name",
      label: "CLIENT NAME",
      accessor: "ClientDetail.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
      },
    },
    {
      name: "product_amount",
      label: "LOAN AMOUNT",
      accessor: "product_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const LoanAmount = Number(tableMeta.rowData[2]).toLocaleString();

          return <p>{LoanAmount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "40px",
          },
        }),
      },
    },
    {
      name: "loanPendingSum",
      label: "LOAN PENDING",
      accessor: "loanPendingSum",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const pendingLoan = Number(tableMeta.rowData[3]).toLocaleString();

          return <p>{pendingLoan}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "40px",
          },
        }),
      },
    },
    {
      name: "loanRepaidSum",
      label: "LOAN PAID",
      accessor: "loanRepaidSum",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const paidLoan = Number(tableMeta.rowData[4]).toLocaleString();
          // console.log(paidLoan)
          return <p>{paidLoan}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "40px",
          },
        }),
      },
    },
    {
      name: "deduct_percent",
      label: "DISCOUNT",
      accessor: "deduct_percent",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const discountValue = tableMeta.rowData[5];
          return <p>{`${discountValue}%`}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "40px",
          },
        }),
      },
    },
    {
      name: "amount",
      label: "INTEREST",
      accessor: "amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData)

          let pendingLoan = Number(tableMeta.rowData[6]);
          // console.log(pendingLoan)

          return <>{(pendingLoan - tableMeta.rowData[2]).toFixed(2)}</>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "40px",
          },
        }),
      },
    },
    {
      name: "is_high_risk",
      label: "HIGH RISK",
      accessor: "is_high_risk",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData[7])

          return (
            <>
              {tableMeta.rowData[7] === true ? (
                <Tooltip title="High Risk">
                  <MDButton color="error" size="small" disabled>
                    High Risk
                  </MDButton>
                </Tooltip>
              ) : (
                <Tooltip title="Low Risk">
                  <MDButton color="success" size="small" disabled>
                    Low Risk
                  </MDButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];
  const options = {
    filter: true,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
    print: true,
    download: true,
    resizableColumns: false,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Formik
        initialValues={{ startDate: "", endDate: "" }}
        validationSchema={DateSchema}
        onSubmit={(values) => {
          const startDate = dayjs(values.startDate).format("YYYY-MM-DD");
          const endDate = dayjs(values.endDate).format("YYYY-MM-DD");
          const dates = {
            startDate,
            endDate,
          };
          dispatch(revenueReport(dates));
        }}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Card>
              <Box display="flex" flexDirection="row" justifyContent="start">
                <Box style={{ margin: "30px" }}>
                  <label htmlFor="startDate">Select Start Date</label>
                  {" "}
                  <input
                    type="date"
                    name="startDate"
                    value={values.startDate}
                    onChange={handleChange}
                    style={{ marginRight: "10px", height: "25px" }}
                  />
                  {errors.startDate && touched.startDate && (
                    <div style={{ fontSize: "0.8rem", color: "red", marginTop: "5px" }}>
                      {errors.startDate}
                    </div>
                  )}
                </Box>

                <Box style={{ margin: "30px" }}>
                  <label htmlFor="endDate">Select End Date</label>
                  {" "}
                  <input
                    type="date"
                    name="endDate"
                    value={values.endDate}
                    onChange={handleChange}
                    style={{ marginRight: "10px" }}
                  />
                  {errors.endDate && touched.endDate && (
                    <div style={{ fontSize: "0.8rem", color: "red", marginTop: "5px" }}>
                      {errors.endDate}
                    </div>
                  )}
                </Box>

                <Box>
                  <MDButton color="info" size="small" type="submit" style={{ margin: "30px" }}>
                    Get Reports
                  </MDButton>
                </Box>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>

      {/* <Formik
        initialValues={{ startDate: "", endDate: "" }}
        validationSchema={DateSchema}
        onSubmit={(values) => {
          let startDate = dayjs(values.startDate).format("YYYY-MM-DD");
          let endDate = dayjs(values.endDate).format("YYYY-MM-DD");
          const dates = {
            startDate: startDate,
            endDate: endDate,
          };
          dispatch(revenueReport(dates));
        }}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Card>
              <Box display="flex" flexDirection="row" justifyContent="start">
                <Box style={{ margin: "30px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Field name="startDate">
                      {({ field }) => (
                        <>
                          <DatePicker
                            {...field}
                            label="Select Start Date"
                            inputFormat="yyyy-MM-dd"
                            value={values.startDate}
                            onChange={(date) =>
                              handleChange({ target: { name: "startDate", value: date } })
                            }
                            style={{ marginRight: "10px" }} // Add margin-right
                          />
                          <ErrorMessage
                            name="startDate"
                            component="div"
                            className="error-message"
                            style={{ fontSize: "0.8rem", color: "red", marginTop: "5px" }}
                          />
                        </>
                      )}
                    </Field>
                  </LocalizationProvider>
                </Box>

                <Box style={{ margin: "30px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Field name="endDate">
                      {({ field }) => (
                        <>
                          <DatePicker
                            {...field}
                            label="Select End Date"
                            inputFormat="yyyy-MM-dd"
                            value={values.endDate}
                            onChange={(date) =>
                              handleChange({ target: { name: "endDate", value: date } })
                            }
                            style={{ marginRight: "10px" }} // Add margin-right
                          />
                          <ErrorMessage
                            name="endDate"
                            component="div"
                            className="error-message"
                            style={{ fontSize: "0.8rem", color: "red", marginTop: "5px" }}
                          />
                        </>
                      )}
                    </Field>
                  </LocalizationProvider>
                </Box>

                <Box>
                  <MDButton
                    color="info"
                    size="small"
                    type="submit"
                    style={{ margin: "30px" }} // Add margin-left
                  >
                    Get Reports
                  </MDButton>
                </Box>
              </Box>
            </Card>
          </Form>
        )}
      </Formik> */}

      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {revenueReportData != null && (
                <MUIDataTable
                  title={"Revenue Report"}
                  data={revenueReportData}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
