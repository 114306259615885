import Icon from "@mui/material/Icon";
import * as ROUTE from "constants/AppRouteName";

import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";

// Product
import CreateProduct from "customComponents/product/CreateProduct";
import ManageProduct from "customComponents/product/ManageProduct";
import ImportProducts from "customComponents/product/ImportProducts";
import EditProduct from "customComponents/product/EditProduct";
// Category
import CategoryManagement from "customComponents/category/list";
import AddCategory from "customComponents/category/add";
import EditCategory from "customComponents/category/edit";

// Business
import BusinessManagement from "customComponents/bussiness/list";
import AddBusinessNature from "customComponents/bussiness/add";
import EditBusinessNature from "customComponents/bussiness/edit";

// Country
import CreateCountry from "customComponents/country/CreateCountry";
import EditCountry from "customComponents/country/EditCountry";
import ImportCountry from "customComponents/country/ImportCountry";
import CountryManagement from "customComponents/country/CountryManagement";

// Occupation
import EditOccupation from "customComponents/occupation/EditOccupation";
import CreateOccupation from "customComponents/occupation/CreateOccupation";
import OccupationManagement from "customComponents/occupation/OccupationManagement";

// Employement Status
import EditEmploymentStatus from "customComponents/employmentStatus/EditEmploymentStatus";
import EmploymentStatusManagement from "customComponents/employmentStatus/EmploymentStatusManagement";
import CreateEmploymentStatus from "customComponents/employmentStatus/CreateEmploymentStatus";

// Merchant Type
import CreateMerchantType from "customComponents/merchantType/CreateMerchantType";
import EditMerchantType from "customComponents/merchantType/EditMerchantType";
import MerchantTypeManagement from "customComponents/merchantType/MerchantTypeManagement";
// Loan Type
import EditLoanType from "customComponents/loanType/EditLoanType";
import CreateLoanType from "customComponents/loanType/CreateLoanType";
import LoanTypeManagement from "customComponents/loanType/LoanTypeManagement";

// User
import EditAppUser from "customComponents/appUser/edit";
import ViewAppUser from "customComponents/appUser/view";
import AppUserList from "customComponents/appUser/list";
import EditUser from "customComponents/user/edit";
import ChangePassword from "customComponents/user/changepassword";
import CreateUser from "customComponents/user/create";
import UserList from "customComponents/user/list";

// Loan details
import LoanView from "customComponents/loan/view";
import ContentList from "customComponents/content/list";
import ContentAdd from "customComponents/content/create";
import ContentEdit from "customComponents/content/edit";

// Credit details

import CreditView from "customComponents/creditRange/view"
import CreateCreditRange from "customComponents/creditRange/createCreditRange";
// Intrest details
import InterestType from "../customComponents/interestType/interestType"
import EditRateofInterest from "customComponents/interestType/edit";
import AddRateofInterest from "customComponents/interestType/add";
// Installment Manager
import CustomerList from "customComponents/installmentManager/customerList";
import CustomerInstallment from "customComponents/installmentManager/customer";
import Revenue from "customComponents/revenueReport/revenueReport";
import MerchantList from "customComponents/merchantList/list";
import TodaysEmiList from "customComponents/installmentManager/todaysEmi";
import OnGoingLoan from "customComponents/loan/onGoingLoans";
import NewLoan from "customComponents/loan/newLoans";
import PendingLoanApplications from "customComponents/loan/approvePendingLoan";
import UserIbanDetails from "customComponents/appUser/iban";
import UserFincludeDetails from "customComponents/appUser/fincludeDetails";
import CompletedLoan from "customComponents/loan/completedLoan";



const adminRoutes = [ 
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "User Manager",
      key: "user-list",
      icon: <Icon fontSize="small">people</Icon>,
      route: "/user-list",
      component: <UserList />,
      nave: "sidebar"
    },
   
    {
      type: "collapse",
      name: "Credit View",
      key: "credit",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/credit-range/view/:id",
      component: <CreditView />,
      nave: "internal"
    }, 
    {
      type: "collapse",
      name: "Create Credit Range",
      key: "createcreditrange",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/create-credit-range",
      component: <CreateCreditRange />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit User",
      key: "edituser",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/user/edit/:id",
      component: <EditUser />,
      nave: "internal"
    },
    {
      type: "collapse",
      name: "Change Password",
      key: "changepassword",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/user/change-password/:id",
      component: <ChangePassword />,
      nave: "internal"
    },
    {
      type: "collapse",
      name: "App User Manager",
      key: "app-users",
      icon: <Icon fontSize="small">phone_android</Icon>,
      route: ROUTE.APP_APP_USER_LIST,
      component: <AppUserList />,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "App Users",
      key: "app user",
      icon: <Icon fontSize="small">phone_android</Icon>,
      route: ROUTE.APP_APP_USER_LIST + '/:id',
      component: <EditAppUser />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "App Users View",
      key: "app user view",
      icon: <Icon fontSize="small">visibility</Icon>,
      route: ROUTE.APP_USER_VIEW + '/:id',
      component: <ViewAppUser />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Loan View",
      key: "loan view",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/loans/view/:id",
      component: <LoanView />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Employment Status Manager",
      key: "employment-status-management",
      icon: <Icon fontSize="small">military_tech</Icon>,
      route: "/employment-status-management",
      component: <EmploymentStatusManagement />,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Create Employment Status",
      key: "createemploymentstatus",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/create-employment-status",
      component: <CreateEmploymentStatus />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit Employment Status",
      key: "editemploymentstatus",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/employment-status/edit/:id",
      component: <EditEmploymentStatus />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Product Category Manager",
      key: "categories",
      icon: <Icon fontSize="small">category</Icon>,
      route: "/categories",
      component: <CategoryManagement/>,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Create Category",
      key: "addcategory",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/categories/add",
      component: <AddCategory/>,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit Category",
      key: "editcategory",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/categories/edit/:id",
      component: <EditCategory/>,
      nave:"internal"
    },  
    {
      type: "collapse",
      name: "Merchant Type Manager",
      key: "merchanttype-management",
      icon: <Icon fontSize="small">store_front</Icon>,
      route: "/merchanttype-management",
      component: <MerchantTypeManagement/>,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Create Merchant Type",
      key: "create-merchanttype",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/create-merchanttype",
      component: <CreateMerchantType />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit Merchant Type",
      key: "editmerchanttype",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/merchant-type/edit/:id",
      component: <EditMerchantType />,
      nave:"internal"
    },
    {
        type: "collapse",
        name: "Sign Up",
        key: "signup",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: ROUTE.APP_CREATE_USER,
        component: <CreateUser />,
        nave: "external"
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: ROUTE.APP_PROFILE,
      component: <Profile />,
      nave:"internal"
    },    
    {
      type: "collapse",
      name: "Content Mangement",
      key: "contents",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/contents",
      component: <ContentList />,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Add Content",
      key: "add content",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/contents/add",
      component: <ContentAdd />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit Content",
      key: "edit content",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/contents/edit/:id",
      component: <ContentEdit />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Rate of Interest ",
      key: "Rate-of-Interest",
      icon: <Icon fontSize="small">store_front</Icon>,
      route: "/Rate-of-Interest",
      component: <InterestType/>,
      nave:"sidebar"
    } , 
    {
      type: "collapse",
      name: "Edit Rate of Interest",
      key: "EditRateofInterest",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/Rate-of-Interest/edit/:id",
      component: <EditRateofInterest />,
      nave:"internal"
    }, 
    {
      type: "collapse",
      name: "Add Rate of Interest",
      key: "AddRateofInterest",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/Rate-of-Interest/Add",
      component: <AddRateofInterest />,
      nave:"internal"
    } , 
    {
      type: "collapse",
      name: "Installment Manager",
      key: "InstallmentManager",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/InstallmentManager",
      component: <CustomerList/>,
      nave:"sidebar"
    } ,
    {
      type: "collapse",
      name: "installment manager edit",
      key: "InstallmentManager/edit",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/installmentmanager/edit/:id",
      component: <CustomerInstallment />,
      nave:"internal"
    } , 
    {
      type: "collapse",
      name: "Revenue Report",
      key: "RevenueReport",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/RevenueReport",
      component: <Revenue/>,
      nave:"sidebar"
    }  , 

    {
      type: "collapse",
      name: "Merchant List",
      key: "merchantList",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/merchantList",
      component: <MerchantList />,
      nave:"internal"
    },
   
    {
      type: "collapse",
      name: "Today's Emi's",
      key: "today'semi",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/today'semi",
      component: <TodaysEmiList />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Ongoing Loans",
      key: "ongoingLoans",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/ongoingLoans",
      component: <OnGoingLoan />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "New Loans",
      key: "NewLoans",
      icon: <Icon fontSize="small">table_view</Icon>,
      route: "/NewLoans",
      component: <NewLoan />,
      nave:"internal"
    },

    {
      type: "collapse",
      name: "Manage Loan Applications",
      key: "ManageLoan",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/ManageLoan",
      component: <PendingLoanApplications/>,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Iban Details",
      key: "IbanDetails",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/app-users/view/IbanDetails",
      component: <UserIbanDetails/>,
      nave:"internal"
    }  , 
    {
      type: "collapse",
      name: "Finclude Details",
      key: "FincludeDetails",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/app-users/view/FincludeDetails",
      component: <UserFincludeDetails/>,
      nave:"internal"
    }  ,
    {
      type: "collapse",
      name: "Completed Loans",
      key: "completedLoans",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/completedLoans",
      component: <CompletedLoan/>,
      nave:"internal"
    }  ,
   
   
 
  
];

export default adminRoutes;