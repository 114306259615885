
import { useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect, { SelectChangeEvent } from '@mui/material/NativeSelect';

import { useEffect, useState } from "react";

import { getCategories } from "api/services/categoryService";
import { addProduct } from "api/services/productService";
import { APP_PRODUCT_LIST } from "constants/AppRouteName";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { updateToastAgent } from "app/appSlice";

export default function CreateProduct() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories } = useSelector((state) => state.appStore);

    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [color, setColor] = useState('');
    const [size, setSize] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const selectFile = ((e) => {
        setImage(e.target.files[0])
    })
    //

    const handleCreate = async () => {

        if (validator.isEmpty(name)) {
            dispatch(updateToastAgent({ t: "e", m: "Name is required." }));
        } else if (validator.isEmpty(barcode)) {
            dispatch(updateToastAgent({ t: "e", m: "Barcode is required." }));
        }else if (validator.isEmpty(description)) {
            dispatch(updateToastAgent({ t: "e", m: "Description is required." }));
        }else if (validator.isEmpty(category+"")) {
            dispatch(updateToastAgent({ t: "e", m: "Category is required." }));
        }else if (validator.isEmpty(price+"")) {
            dispatch(updateToastAgent({ t: "e", m: "Price is required." }));
        } else {
            let data = new FormData();
            
            data.append('image', image);
            data.append('name', name);
            data.append('barcode', barcode);
            data.append('description', description);
            data.append('category', category);
            data.append('price', price);
            data.append('color', color);
            data.append('size', size);

            dispatch(addProduct(data, APP_PRODUCT_LIST, navigate));
        }

    }
    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Create Product
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter product details to create
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Name" variant="standard" onChange={(e) => setName(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Barcode" variant="standard" onChange={(e) => setBarcode(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Description" variant="standard" onChange={(e) => setDescription(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <NativeSelect name="category" onChange={(e) => setCategory(e.target.value)} fullWidth>

                                        <option value="">Select Category</option>
                                        {categories?.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })}
                                    </NativeSelect>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="number" label="Price" variant="standard" onChange={(e) => setPrice(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="text" label="Color" variant="standard" onChange={(e) => setColor(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="size" label="Size" variant="standard" onChange={(e) => setSize(e.target.value)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput type="file" label="Product Image" variant="standard" onChange={(e) => selectFile(e)} fullWidth />
                                </MDBox>
                            </Grid>
                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                <MDButton variant="gradient" color="info" onClick={handleCreate}>
                                    Create
                                </MDButton>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
