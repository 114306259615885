import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { TextField } from "@material-ui/core";
import MDButton from "components/MDButton";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditRange,
  deleteCreditRange,
  updateCreditRange,
} from "api/services/creditRangeService";
import { APP_CREDIT_RANGE_LIST } from "../../constants/AppRouteName";
import { updateLoader, setCreditRange } from "app/appSlice";
import { Switch } from "@mui/material";
import switchButton from "assets/theme-dark/components/form/switchButton";

export default function ViewCreditRange() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data: user, creditRange } = useSelector((state) => state.appStore);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(updateLoader(true));
        await dispatch(getCreditRange(id));
        dispatch(updateLoader(false));
      } catch (error) {
        console.error("Error fetching credit range:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography display="block" variant="button" color="white" my={1}>
            Credit Range Details
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <h3>Range Information</h3>
                <hr />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="From Credit Score"
                    name="Credit_Score_From"
                    value={creditRange?.lowerlimit_Creditscore}
                    onChange={(e) =>
                      dispatch(
                        setCreditRange({
                          ...creditRange,
                          lowerlimit_Creditscore: parseInt(e.target.value),
                        })
                      )
                    }
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="To Credit Score"
                    name="Credit_Score_To"
                    value={creditRange?.upperlimit_Creditscore}
                    onChange={(e) =>
                      dispatch(
                        setCreditRange({
                          ...creditRange,
                          upperlimit_Creditscore: parseInt(e.target.value),
                        })
                      )
                    }
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  {creditRange?.Status === "false" ? (
                    <>
                      <Switch
                        checked={true}
                        onChange={() => {
                          dispatch(setCreditRange({ ...creditRange, Status: "true" }));
                        }}
                      />
                      REJECTED
                      {/* <Checkbox
                        checked={true}
                        onChange={() => {
                          dispatch(setCreditRange({ ...creditRange, Status: "true" }));
                        }}
                      />
                      REJECT */}
                    </>
                  ) : (
                    <>
                      <Switch
                        checked={false}
                        onChange={() => {
                          dispatch(setCreditRange({ ...creditRange, Status: "false" }));
                        }}
                      />
                      ACCEPTED
                    </>
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Loan Amount"
                    name="loan_amount"
                    value={creditRange?.loan_Amount}
                    onChange={(e) => {
                      dispatch(
                        setCreditRange({ ...creditRange, loan_Amount: parseInt(e.target.value) })
                      );
                    }}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Interest Rate"
                    name="interest_rate"
                    value={creditRange?.interest_Rate}
                    onChange={(e) => {
                      dispatch(
                        setCreditRange({ ...creditRange, interest_Rate: parseInt(e.target.value) })
                      );
                    }}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Number Of Installments"
                    name="number_of_installments"
                    value={creditRange?.number_Of_Installments}
                    onChange={(e) => {
                      dispatch(
                        setCreditRange({
                          ...creditRange,
                          number_Of_Installments: parseInt(e.target.value),
                        })
                      );
                    }}
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDButton
              color="success"
              size="small"
              onClick={() => {
                dispatch(updateCreditRange({ ...creditRange }));
              }}
            >
              Update
            </MDButton>
            <MDButton
              color="error"
              size="small"
              onClick={() => {
                dispatch(deleteCreditRange(parseInt(id), navigate, APP_CREDIT_RANGE_LIST));
              }}
            >
              Delete
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>

    // </CoverLayout>
  );
}
