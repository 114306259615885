import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect from '@mui/material/NativeSelect';
import { Formik, Form, ErrorMessage } from "formik";
import { AddRateOfInterestSchema } from "app/validationSchema";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCategory, getCategories } from "api/services/categoryService";
import { APP_RATE_OF_INTEREST } from "constants/AppRouteName";

export default function AddRateofInterest() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories } = useSelector((state) => state.appStore);
    const [filteredCatagoriesData, setfilteredCatagoriesData] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("");

    const productData = (array, name) => {
        const foundObject = array.find(obj => obj.name === name);
        return foundObject ? foundObject : null;
    };





    useEffect(async () => {
        dispatch(getCategories());
    }, []);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);

    };

    const handleUpdate = async (formValues) => {
        const dataofSelectedCategory = productData(categories, formValues.productcategory)
        const updatedRateofInterestData = {
            name: formValues.productcategory,
            id: dataofSelectedCategory.id,
            GeneralRateOfInterest: formValues.GeneralRateOfInterest,
            HighRiskRateOfInterest: formValues.HighRiskRateOfInterest,
            description: dataofSelectedCategory.description

        }
        
        dispatch(updateCategory(updatedRateofInterestData, APP_RATE_OF_INTEREST, navigate));

    }



    function filterCategories(categories) {
        if (categories != null) {
            return categories.filter(category => category.GeneralRateOfInterest === 0 && category.HighRiskRateOfInterest === 0);
        }

    }

    useEffect(() => {
        const filteredCatagories = filterCategories(categories)
        setfilteredCatagoriesData(filteredCatagories)

    }, [categories])


    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Add Rate Of Interest
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        {name}
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>


                    <Formik
                        initialValues={{
                            GeneralRateOfInterest: '',
                            HighRiskRateOfInterest: '',
                            productcategory: ''
                        }}
                        validationSchema={AddRateOfInterestSchema}
                        onSubmit={(values) => handleUpdate(values)}
                    >
                        {(formik) => {
                            const {
                                values,
                                handleChange,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;
                            return (
                                <Form>
                                    <MDBox >
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <MDBox mb={2} mt={1.5}>
                                                    <NativeSelect
                                                        id="productcategory"
                                                        name="productcategory"
                                                        value={values.productcategory}
                                                        fullWidth
                                                        onChange={(e) => {
                                                            handleChange(e);

                                                        }}
                                                        onBlur={handleBlur}
                                                        error={errors.productcategory && touched.productcategory}
                                                    >
                                                        <option value="">Select Product Category</option>
                                                        {categories ? filteredCatagoriesData.map((ty, i) => {
                                                            return (
                                                                <option key={i} value={ty.name}>{ty.name}</option>
                                                            )
                                                        }) : ''}
                                                    </NativeSelect>
                                                    {errors.productcategory && touched.productcategory && (
                                                        <span className="error">{errors.productcategory}</span>
                                                    )}
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={6}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" name="GeneralRateOfInterest" label="General Rate Of Interest" variant="standard" value={values.GeneralRateOfInterest} fullWidth onChange={handleChange} onBlur={handleBlur}
                                                        className={errors.GeneralRateOfInterest && touched.GeneralRateOfInterest ?
                                                            "input-error" : "null"} />
                                                    <ErrorMessage name="GeneralRateOfInterest" component="span" className="error" />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" name="HighRiskRateOfInterest" label="High Risk Rate Of Interest" variant="standard" value={values.HighRiskRateOfInterest} fullWidth onChange={handleChange} onBlur={handleBlur}
                                                        className={errors.HighRiskRateOfInterest && touched.HighRiskRateOfInterest ?
                                                            "input-error" : "null"} />
                                                    <ErrorMessage name="HighRiskRateOfInterest" component="span" className="error" />
                                                </MDBox>
                                            </Grid>
                                            <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                                                <MDButton variant="gradient" color="info" type="submit" className={dirty && isValid ? "" : "disabled-btn"}
                                                    disabled={!(dirty && isValid)}>
                                                    Add
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Form>
                            );
                        }}
                    </Formik>


                </MDBox>

            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
