import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
export const appSlice = createSlice({
  name: 'appStore',
  initialState,
  reducers: {
    updateLoginDetails: (state, action) => {
      state.data = action.payload.data
      state.token = action.payload.token
    },
    logoutSuccess: (state, action) => {
      localStorage.clear();
      state.data = null;
      state.token = null;
      state.type = null;
      state.toastAgent = {};
      state.loader = false;
      state.users = null;
      state.user = null;
      state.customer = null;
      state.merchantTypes = null;
      state.merchantType = null;
      state.category = null;
      state.categories = null;
      state.business = null;
      state.businesses = null;
      state.countries = null;
      state.country = null;
      state.employmentstatuses = null;
      state.employmentstatus = null;
      state.loantypes = null;
      state.loantype = null;
      state.occupations = null;
      state.occupation = null;
      state.products = null;
      state.product = null;
      state.loans = null;
      state.creditRanges = null;
      state.creditRange = null;
      state.loan = null;
      state.contents = null;
      state.content = null;
      state.merchantLoanStatics = null;
      state.dashboardData = null;

    },
    updateType: (state, action) => {
      state.type = action.payload.data
    },
    updateToastAgent: (state, action) => {
      state.toastAgent = action.payload
    },
    updateLoader: (state, action) => {
      state.loader = action.payload
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setCustomer: (state, action) => {
      state.customer = action.payload
    },
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    setMerchantTypes: (state, action) => {
      state.merchantTypes = action.payload
    },
    setMerchantType: (state, action) => {
      state.merchantType = action.payload
    },
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setDeductPercent: (state, action) => {
      state.deductPercent = action.payload
    },
    setBusinesses: (state, action) => {
      state.businesses = action.payload
    },
    setBusinessNature: (state, action) => {
      state.business = action.payload
    },
    setCountries: (state, action) => {
      state.countries = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
      
    },
    setEmploymentStatuses: (state, action) => {
      state.employmentstatuses = action.payload
    },
    setEmploymentStatus: (state, action) => {
      state.employmentstatus = action.payload
    },
    setLoanTypes: (state, action) => {
      state.loantypes = action.payload
    },
    setLoanType: (state, action) => {
      state.loantype = action.payload
    },
    setOccupations: (state, action) => {
      state.occupations = action.payload
    },
    setOccupation: (state, action) => {
      state.occupation = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    setLoans: (state, action) => {
      state.loans = action.payload
    },
    setCreditRanges: (state, action) => {
      state.creditRanges = action.payload
    },
    setCreditRange: (state, action) => {
      state.creditRange = action.payload
    },
    setLoan: (state, action) => {
      state.loan = action.payload
    },
    setContents: (state, action) => {
      state.contents = action.payload
    },
    setContent: (state, action) => {
      state.content = action.payload
    },
    setMerchantLoanData: (state, action) => {
      state.merchantLoanStatics = action.payload
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload
    },
    setCustomersInstallmentsList: (state, action) => {
      state.CustomerInstallmentsList = action.payload
    },
    setSingleCustomerInstallmentsList: (state, action) => {
      state.SingleCustomerInstallmentsList = action.payload
    },
    setUserEmail: (state, action) => {
      state.UserEmail = action.payload
    },
    setOTP: (state, action) => {
      state.OTPForResetPassword = action.payload
    },
    setrevenueReportData: (state, action) => {
      state.revenueReportData = action.payload
    },
    setTodaysInstallmentList: (state, action) => {
      state.todaysInstallmentList = action.payload
    },
    setNewLoans: (state, action) => {
      state.newLoans = action.payload
    },
    setOnGoingLoans: (state, action) => {
      state.onGoingLoans = action.payload
    },
    setApplliedLoans: (state, action) => {
      state.appliedLoans = action.payload
    },
    setCompletedLoans: (state, action) => {
      state.completedLoans = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateLoginDetails,
  updateType,
  logoutSuccess,
  updateToastAgent,
  updateLoader,
  setUsers,
  setUser,
  setMerchantTypes,
  setMerchantType,
  setCategory,
  setCategories,
  setBusinesses,
  setBusinessNature,
  setCountries,
  setCountry,
  setLoanTypes,
  setLoanType,
  setOccupations,
  setOccupation,
  setProducts,
  setProduct,
  setEmploymentStatuses,
  setEmploymentStatus,
  setLoans,
  setCreditRanges,
  setCreditRange,
  setLoan,
  setCustomer,
  setContents,
  setContent,
  setMerchantLoanData,
  setDashboardData , 
  setCustomersInstallmentsList,
  setSingleCustomerInstallmentsList , 
  setUserEmail , 
  setOTP , 
  setrevenueReportData , 
  setTodaysInstallmentList , 
  setNewLoans , 
  setOnGoingLoans , 
  setApplliedLoans , 
  setCompletedLoans , 
  setCustomers
  
} = appSlice.actions

export default appSlice.reducer