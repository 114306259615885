//constant like api paths
export const LOGIN = "/login";
export const RESET_PASSWORD = "/forgot-password";
export const GET_USERS = "/users";
export const USER = "/user";
export const CREATE_USER = "/user-add";
export const CHANGE_PASSWORD = "/user/change-password";
export const GET_MERCHANT_LOAN_AMOUNT = "/merchants-loan-data";
export const GET_DASHBOARD_DATA = (data) => `/get-dashboard-data/${data.role}/${data.id}`;


//variable like api paths
export const GET_USER = (data) => `/user/${data.id}`;
export const GET_APPUSER = (data) => `/customer/${data.id}`;

export const CHANGE_STATUS = (data) => `/customer/${data.id}/${data.status}`;

export const CATEGORY = "/categories";
export const GET_CATEGORY = (data) => `/categories/${data.id}`;
export const UPDATE_CATEGORY = (data) => `/categories/${data.id}`;
export const DELETE_CATEGORY = (data) => `/categories/remove/${data.id}`;


export const BUSINESSES = `/business-nature`;
// this url is used for get details and update details
export const GET_BUSINESS = (data) => `/business-nature/${data.id}`;
export const DELETE_BUSINESS = (data) => `/business-nature/remove/${data.id}`;


export const COUNTRIES = `/countrys`;
export const GET_COUNTRY = (data) => `/country/${data.id}`;
export const UPDATE_COUNTRY = `/country`;
export const IMPORT_COUNTRY = `/import-country`;
export const ADD_COUNTRY = `/country/add`;
export const DELETE_COUNTRY = (data) => `/country/remove/${data.id}`;

// this url is used for get list and add data
export const EMPLOYMENTSTATUSES = `/employment-status`;
// this url is used for get details and update details
export const GET_EMPLOYMENTSTATUS = (data) => `/employment-status/${data.id}`;
export const DELETE_EMPLOYMENTSTATUS = (data) => `/employment-status/remove/${data.id}`;

// this url is used for get list and add data
export const LOANTYPES = `/loan-type`;
// this url is used for get details and update details
export const GET_LOANTYPE = (data) => `/loan-type/${data.id}`;
export const DELETE_LOANTYPE = (data) => `/loan-type/remove/${data.id}`;


// this url is used for get list and add data
export const GET_MERCHANT_TYPES = "/merchant-type";
// this url is used for get details and update details
export const GET_MERCHANTTYPE = (data) => `/merchant-type/${data.id}`;
export const DELETE_MERCHANTTYPE = (data) => `/merchant-type/remove/${data.id}`;

// this url is used for get list and add data
export const GET_OCCUPATIONS = "/occupations";
// this url is used for get details and update details
export const GET_OCCUPATION = (data) => `/occupations/${data.id}`;
export const DELETE_OCCUPATION = (data) => `/occupations/remove/${data.id}`;



// this url is used for get list and add data
export const GET_PRODUCTS = "/products";
export const IMPORT_PRODUCTS = "/import-product";
// this url is used for get details and update details
export const GET_PRODUCT = (data) => `/products/${data.id}`;
export const DELETE_PRODUCT = (data) => `/products/delete/${data.id}`;
export const PRODUCT_CHANGE_STATUS = (data) => `/products/${data.id}/${data.status}`;

// App Users
export const GET_APP_USERS = "/app-users";

//Credit score range
export const GET_CREDIT_RANGES = "/getLoanParameters"

//Single credit score range
export const GET_CREDIT_RANGE = "/getSingleLoanParamaterData"

//Delete Credit score range
export const DELETE_CREDIT_RANGE = "/deleteLoanParameters"

//Update Credit score range
export const UPDATE_CREDIT_RANGE = "/updateLoanParameters"

//Add Credit score range
export const CREATE_CREDIT_RANGE = "/createLoanParameters"

//Credit Range status change
export const CHANGE_CREDIT_STATUS = "/updateLoanParametersStatus"



// Loans list
export const GET_LOANS = "/loans";
//loan data details
export const GET_LOAN = (data) => `/loans/${data.id}`;
export const LOAN_CHANGE_STATUS = (data) => `/loans/${data.id}/${data.status}`;
export const LOAN_DOWNLOAD_DOCUMENT = (data) => `/loans/signed-document/${data.id}`;



export const UPDATE_CONTENT = "/contents/update";
export const CREATE_CONTENT = "/contents/add";
// contents list
export const GET_CONTENTS = "/contents";
//contents data details
export const GET_CONTENT = (data) => `/contents/${data.id}`;

export const INSTALLMENT_LIST = "/emidata";
export const SINGLE_CUSTOMER_INSTALLMENT =(data) => `/emidata/${data.id}`;
export const UPDATE_INSTALLMENT_STATUS = '/paymentstatus';
export const SET_USERS_NEW_PASSWORD = '/verifyotp';
export const ACCEPT_LOAN_BY_MERCHANT = '/loanrequest';
export const GET_REVENUE_REPORT = '/revenuereport'; 
export const GET_NEW_LOANS = '/newloans'; 
export const GET_ONGOING_LOANS = '/ongoingloans' ;
export const GET_APPLIED_LOANS = '/appliedloans';
export const UPDATE_APPLIED_LOAN_STATUS = '/loanstatus';
export const GET_COMPLETED_LOAN_STATUS = '/completedloans'
