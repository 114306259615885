import { CountryCodes } from "validator/lib/isISO31661Alpha2";
import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Email must be a valid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(10, "Password is too short - should be 10 chars minimum"),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email must be a valid email").required("Email is required"),
});

export const CreateUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "First Name is too short - should be 3 chars minimum"),
  last_name: Yup.string()
    .required("last Name is required")
    .min(3, "Last Name is too short - should be 3 chars minimum"),
  email: Yup.string().email("Email must be a valid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(10, "Password is too short - should be 10 chars minimum")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  role: Yup.string().required("Role is required"),
  merchant_type: Yup.string().when("role", {
    is: "2",
    then: Yup.string().required("Merchant type is required"),
  }),
  address: Yup.string().when("role", {
    is: "2",
    then: Yup.string().required("Address is required"),
  }),
  
  registered_phone_number: Yup.number().when("role", {
    is: "2",
    then: Yup.number()
      .required("Registered Phone Number is required")
      .min(1000000000, "Registered Phone Number must be at least 10 digits")
      .max(9999999999, "Registered Phone Number must be at most 10 digits")
      .notOneOf(
        [Yup.ref("alternate_phone_number")],
        "Registered Phone Number cannot be the same as Alternate Phone Number"
      ),
  }),
  alternate_phone_number: Yup.number().when("role", {
    is: "2",
    then: Yup.number()
      .required("Alternate Phone Number is required")
      .min(1000000000, "Alternate Phone Number must be at least 10 digits")
      .max(9999999999, "Alternate Phone Number must be at most 10 digits")
      .notOneOf(
        [Yup.ref("registered_phone_number")],
        "Alternate Phone Number cannot be the same as Registered Phone Number"
      ),
  }),
  deduct_percent: Yup.array().when("role", {
    is: "2",
    then: Yup.array()
      .of(
        Yup.object().shape({
          category_id: Yup.string().required("Product category is required"),
          deduct_percent: Yup.number()
            .required("Deduct percent is required")
            .min(0, "Deduct percent must be greater than or equal to 0")
            .max(50, "Deduct percent must be less than or equal to 50"),
        })
      )
      .test(
        "at-least-one",
        "At least one product category and deduct percent must be selected",
        function (value) {
          if (!value) return false;
          return value.some((item) => !!item.category_id && !!item.deduct_percent);
        }
      ),
  }),

  registration_number: Yup.string().when("role", {
    is: "2",
    then: Yup.string()
      .required("Merchant Registration Number is required")
      .min(8, "Merchant Registration Number is too short - should be 8 chars minimum")
      .max(20, "Merchant Registration Number is too long - should be 20 chars maximum")
      .matches(/^[a-zA-Z0-9]*$/, "Merchant Registration Number can only contain alphanumeric characters") })
}
);

export const ChangePasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("Password is required")
    .min(10, "Password is too short - should be 10 chars minimum")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
      "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

export const EditUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .min(3, "First Name is too short - should be 3 chars minimum"),
  last_name: Yup.string()
    .required("Last Name is required")
    .min(3, "Last Name is too short - should be 3 chars minimum"),
  role: Yup.string().required("Role is required"),
  merchant_type: Yup.string().when("role", {
    is: "2",
    then: Yup.string().required("Merchant type is required"),
  }),
  address: Yup.string().when("role", {
    is: "2",
    then: Yup.string().required("Address is required"),
  }),
  registered_phone_number: Yup.number().when("role", {
    is: "2",
    then: Yup.number()
      .required("Registered Phone Number is required")
      .min(1000000000, "Registered Phone Number must be at least 10 digits")
      .max(9999999999, "Registered Phone Number must be at most 10 digits")
      .notOneOf(
        [Yup.ref("alternate_phone_number")],
        "Registered Phone Number cannot be the same as Alternate Phone Number"
      ),
  }),
  alternate_phone_number: Yup.number().when("role", {
    is: "2",
    then: Yup.number()
      .required("Alternate Phone Number is required")
      .min(1000000000, "Alternate Phone Number must be at least 10 digits")
      .max(9999999999, "Alternate Phone Number must be at most 10 digits")
      .notOneOf(
        [Yup.ref("registered_phone_number")],
        "Alternate Phone Number cannot be the same as Registered Phone Number"
      ),
  }),
  deduct_percent: Yup.array().when("role", {
    is: "2",
    then: Yup.array().of(
      Yup.object().shape({
        category_id: Yup.string().required("Product Category is required"),
        deduct_percent: Yup.number()
          .required("Deduct Percent is required")
          .min(0, "Deduct Percent cannot be negative")
          .max(50, "Deduct Percent cannot be more than 50"),
      })
    ),
  }),
});

export const CreateCountrySchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter the required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
});

export const CreateCreditRangeSchema = Yup.object().shape({
  creditScoreFrom: Yup.number().required("Credit Score From is required"),
  creditScoreTo: Yup.number().required("Credit Score To is required"),
  status: Yup.boolean().required("Status is required"),
  loanAmount: Yup.number().required("Loan amount is required"),
  interestRate: Yup.number().required("Interest Rate is required"),
  numberOfInstallments: Yup.number().required("Number Of Installments is required"),
});

export const RateOfInterestSchema = Yup.object().shape({
  GeneralRateOfInterest: Yup.number()
    .required("General Rate Of Interest is required")
    .typeError("General Rate Of Interest must be a number")
    .integer("General Rate Of Interest must be an integer")
    .min(0, "General Rate Of Interest cannot be less than 0")
    .max(99, "General Rate Of Interest cannot be more than 99")
    .test('less-than-high-risk', 'General Rate Of Interest must be less than High Risk Rate Of Interest', function(value) {
      const { HighRiskRateOfInterest } = this.parent;
      return value < HighRiskRateOfInterest;
    }),
  HighRiskRateOfInterest: Yup.number()
    .required("HighRisk Rate Of Interest is required")
    .typeError("HighRisk Rate Of Interest must be a number")
    .integer("HighRisk Rate Of Interest must be an integer")
    .min(0, "HighRisk Rate Of Interest cannot be less than 0")
    .max(99, "HighRisk Rate Of Interest cannot be more than 99"),
});

export const AddRateOfInterestSchema = Yup.object().shape({
  GeneralRateOfInterest: Yup.number()
    .required("General Rate Of Interest is required")
    .typeError("General Rate Of Interest must be a number")
    .integer("General Rate Of Interest must be an integer")
    .min(0, "General Rate Of Interest cannot be less than 0")
    .max(99, "General Rate Of Interest cannot be more than 99")
    .test('less-than-high-risk', 'General Rate Of Interest must be less than High Risk Rate Of Interest', function(value) {
      const { HighRiskRateOfInterest } = this.parent;
      return value < HighRiskRateOfInterest;
    }),
  HighRiskRateOfInterest: Yup.number()
    .required("HighRisk Rate Of Interest is required")
    .typeError("HighRisk Rate Of Interest must be a number")
    .integer("HighRisk Rate Of Interest must be an integer")
    .min(0, "HighRisk Rate Of Interest cannot be less than 0")
    .max(99, "HighRisk Rate Of Interest cannot be more than 99"),
  productcategory: Yup.string()
    .required("Product Category is required")
    .typeError("Product Category must be a string")
});


export const DateSchema = Yup.object().shape({
  startDate: Yup.date()
    .typeError('Start Date must be a valid date')
    .required('Start Date is required'),
  endDate: Yup.date()
    .typeError('End Date must be a valid date')
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after Start Date')
    .max(new Date(), 'End Date cannot be a future date')
});

export const merchantTypeValidationSchema = Yup.object().shape({
  name: Yup.string().min(5, "Name must be at least 5 characters long").required("Name is required"),
});

export const categoryValidationSchema = Yup.object().shape({
  name: Yup.string().min(5, "Name must be at least 5 characters long").required("Name is required"),
  description: Yup.string().min(5, "Description must be at least 5 characters long").required("Description is required"),
});

export const employmentStatusValidationSchema = Yup.object().shape({
  name: Yup.string().required("Employment status name is required").min(5, "Employment status name must be at least 5 characters"),
});
