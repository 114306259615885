import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { useDispatch, useSelector } from "react-redux";


import { CONFIRM_DELETE_ROI } from "constants/ConfirmMessage";
import ConfirmBox from "../general/ConfirmBox";
import { getCategories , updateCategory } from "api/services/categoryService";
import { APP_RATE_OF_INTEREST } from "constants/AppRouteName";

export default function InterestType() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const [filteredCatagoriesData , setfilteredCatagoriesData] = useState([])
    const { categories } = useSelector((state) => state.appStore);

 
   
    const muiCache = createCache({
        "key": "mui",
        "prepend": true
    });

    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FF0000"
                    }
                }
            }
        }
    })

  
    const columns = [

        {
            name: "id",
            label: "ID",
            accessor: "id",
            options: {
                filter: false,
                sort: false,
                empty: false,
                "display": false,
                "viewColumns": false
            }
        },
        {
            name: "name",
            label: "Product Category",
            accessor: "name",
            options: {
                filter: true,
                sort: true,
                empty: true
            }
        },
        
        {
            name: "GeneralRateOfInterest",
            label: "General Rate of Interest",
            accessor: "GeneralRateOfInterest",
            options: {
                filter: false,
                sort: false,
                empty: false , 
                setCellProps: () => ({
                    style: {
                        textAlign: 'left',
                        paddingLeft: '160px',
                    },
                  }),
            }
        },
        {
            name: "HighRiskRateOfInterest",
            label: "High Risk Rate of Interest",
            accessor: "HighRiskRateOfInterest",
            options: {
                filter: false,
                sort: false,
                empty: false , 
                setCellProps: () => ({
                    style: {
                      textAlign: 'left',
                      paddingLeft: '170px',
                    },
                  }),
            }
        } , 
        {
            name: "Actions",
            label: "Actions",
            accessor: "actions",
            options: {
                filter: true,
                sort: true,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                     return (
                        <>
                            <Link to={`/Rate-of-Interest/edit/${tableMeta.rowData[0]}`}>
                                <MDButton color="info" size="small">
                                    Edit
                                </MDButton>
                            </Link>
                            <MDButton color="error" size="small" onClick={() => {
                                console.log(tableMeta.rowData[0])
                                handleDelete(tableMeta.rowData[0])
                            }}>
                                Delete
                            </MDButton>
                            

                        </>
                    );
                }
            }
        }
    ]
    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        search: false,
        selectableRows: "none"
    };
    
    useEffect(async () => {
        dispatch(getCategories());
    }, []);

    function filterCategories(categories) {
        if(categories!= null){
         return categories.filter(category => category.GeneralRateOfInterest !== 0 && category.HighRiskRateOfInterest !== 0);
        }
        
     }

     useEffect(()=>{
        const filteredCatagories = filterCategories(categories)
        setfilteredCatagoriesData(filteredCatagories)

     } , [categories])
 
    
 

    const handleDelete = (id) => {
        setId(id);
        setOpen(true);
    }
    const handleOption = async () => {
        setOpen(false);
        const selectedObject = filteredCatagoriesData.find(obj => obj.id === id);
         const filteredData =  filteredCatagoriesData.filter((obj)=>{
            return obj.id !== id
        })
       

        const dataToUpdate = {
            name : selectedObject.name , 
            id :  selectedObject.id , 
            GeneralRateOfInterest :0 , 
            HighRiskRateOfInterest : 0 , 
            description : selectedObject.description

        }

        dispatch(updateCategory(dataToUpdate, APP_RATE_OF_INTEREST, navigate));
        setfilteredCatagoriesData(filteredData)

        
    }

    const handleCreate = async () => {
        navigate("/Rate-of-Interest/Add");
    }
   
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card paddingLeft="30%">
            <MDBox align="right" marginRight="5%" marginTop="1%" marginBottom="1%">
                    <MDButton align="right" color="info" size="small" onClick={handleCreate}>Add Rate of Interest</MDButton>
                </MDBox>
                <MDBox>
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                        {categories != null && <MUIDataTable title={"Interest Type"} data={filteredCatagoriesData} columns={columns} options={options} />}
                        </ThemeProvider>
                    </CacheProvider>
                </MDBox>

            </Card>
            <ConfirmBox
                open={open}
                setOpen={setOpen}
                message={CONFIRM_DELETE_ROI}
                handleOption={handleOption}
            />
        </DashboardLayout>
    )
}
