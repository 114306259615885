export const CONFIRM_DELETE_PRODUCT =  "Are you sure to delete this product?";
export const CONFIRM_DELETE_COUNTRY =  "Are you sure to delete this country?";
export const CONFIRM_DELETE_OCCUPATION_MANAGER =  "Are you sure to delete this occupation?";
export const CONFIRM_DELETE_EMPLOYEE_STATUS_MANAGER =  "Are you sure to delete this employee status?";
export const CONFIRM_DELETE_LOAN_TYPE_MANAGER =  "Are you sure to delete this loan type?";
export const CONFIRM_DELETE_MERCHANT_TYPE_MANAGER =  "Are you sure to delete this merchant type?";
export const CONFIRM_DELETE_CATEGORY_MANAGER =  "Are you sure to delete this category?";
export const CONFIRM_DELETE_BUSINESS_NATURE_MANAGER =  "Are you sure to delete this business nature?";
export const CONFIRM_STATUS_CHANGE_USER =  "Are you sure to make user status inactive ?";
export const CONFIRM_DELETE_RATE_OF_INTEREST =  "Are you sure to delete rate of interest?";
export const OTP_SEND_SUCESSFULLY = "otp send sucessfully"; 
export const CONFIRM_DELETE_ROI =  "Are you sure to delete the RoI of this category";
