import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getCompletedLoans } from "api/services/loanService";


export default function CompletedLoan() {
  const dispatch = useDispatch();
  const { completedLoans } = useSelector((state) => state.appStore);
  // console.log(completedLoans)

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
   
    {
      name: "CustomerModel.name",
      label: " Name",
      accessor: "CustomerModel.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "amount",
      label: "Loan Amount",
      accessor: "amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const loanAmount = Number(tableMeta.rowData[1]).toLocaleString();
         
          return <p>{loanAmount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'left',
          paddingLeft: '50px',
        },
      }),
      },
    },
    {
        name: "product_amount",
        label: "Product Amount",
        accessor: "product_amount",
        options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const product_amount = Number(tableMeta.rowData[2]).toLocaleString();
           
            return <p>{product_amount}</p>;
        } , 
        setCellProps: () => ({
          style: {
            textAlign: 'left',
          paddingLeft: '90px',
          },
        }),
        },
      },
    {
      name: "installments",
      label: "Total Number of EMI",
      accessor: "installments",
      options: {
        filter: true,
        sort: true,
        empty: true,
        setCellProps: () => ({
          style: {
            textAlign: 'left',
          paddingLeft: '140px',
          },
        }),
      },
    },
    {
      name: "first_installment_amount",
      label: "Installment Amount",
      accessor: "first_installment_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const installment_amount = Number(tableMeta.rowData[4]).toLocaleString();
         
          return <p>{installment_amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'left',
          paddingLeft: '120px',
        },
      }),
      },
    },
    // {
    //     name: "LoanDetail.is_high_risk",
    //     label: "High Risk",
    //     accessor: "LoanDetail.is_high_risk",
    //     options: {
    //       filter: true,
    //       sort: true,
    //       empty: true,
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         // console.log(tableMeta.rowData)
            
    //         return <>{tableMeta.rowData[5] === false? "false" : "true"}</>;
    //       },
    //     },
    //   },

  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
  
  };


  useEffect(async () => {
  
    getCompletedLoans(dispatch)
   
  }, []);






  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {completedLoans != null && (
                <MUIDataTable
                  title={"Completed Loans"}
                  data={completedLoans}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    
    </DashboardLayout>
  );
}
