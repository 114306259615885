import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Switch } from "@mui/material";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateToastAgent } from "app/appSlice";
import { createCreditRange } from "api/services/creditRangeService";
import { APP_CREDIT_RANGE_LIST } from "constants/AppRouteName";
import { Formik, Form, ErrorMessage } from "formik";
import { CreateCreditRangeSchema } from "app/validationSchema";
import { initialState } from "app/initialState";
import { CheckBox } from "@mui/icons-material";

export default function CreateCreditRange() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm = (values) => {
    const formData = {
      lowerlimit_Creditscore: values["creditScoreFrom"],
      upperlimit_Creditscore: values["creditScoreTo"],
      Status: values.status,
      loan_Amount: values["loanAmount"],
      interest_Rate: values["interestRate"],
      number_Of_Installments: values["numberOfInstallments"],
    };

    dispatch(createCreditRange(formData, APP_CREDIT_RANGE_LIST, navigate));
  };

  return (
    // <CoverLayout image={bgImage}>
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create Credit Range
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter credit details to create
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialState.createCreditRange}
            validationSchema={CreateCreditRangeSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const { values, handleChange, errors, touched, handleBlur, isValid, dirty } = formik;
              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          id="creditScoreFrom"
                          name="creditScoreFrom"
                          label="Credit Score From"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.name && touched.name ? "input-error" : "null"}
                        />
                        <ErrorMessage name="creditScoreFrom" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          id="creditScoreTo"
                          name="creditScoreTo"
                          label="Credit Score To"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.code && touched.code ? "input-error" : "null"}
                        />
                        <ErrorMessage name="creditScoreTo" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          id="status"
                          name="status"
                          label="Status"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.name && touched.name ? "input-error" : "null"}
                        />
                        <ErrorMessage name="status" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          id="loanAmount"
                          name="loanAmount"
                          label="Loan Amount"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.code && touched.code ? "input-error" : "null"}
                        />
                        <ErrorMessage name="loanAmount" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          id="interestRate"
                          name="interestRate"
                          label="Interest Rate"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.name && touched.name ? "input-error" : "null"}
                        />
                        <ErrorMessage name="interestRate" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          id="numberOfInstallments"
                          name="numberOfInstallments"
                          label="Number Of Installments"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.code && touched.code ? "input-error" : "null"}
                        />
                        <ErrorMessage
                          name="numberOfInstallments"
                          component="span"
                          className="error"
                        />
                      </MDBox>
                    </Grid>
                    <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        className={dirty && isValid ? "" : "disabled-btn"}
                        disabled={!(dirty && isValid)}
                      >
                        Create
                      </MDButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>
    // </CoverLayout>
  );
}
