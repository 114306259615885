export const APP_USER_LIST = "/user-list";
export const APP_CATEGORY_LIST = "/categories";
export const APP_BUSINESS_LIST = "/business-nature";
export const APP_RESET_PASSOWRD = "/reset-password";
export const APP_LOGIN = "/login";
export const APP_DASHBOARD = "/dashboard";
export const APP_COUNTRY_LIST = "/country-management";
export const APP_EMPLOYMENT_STATUS_LIST = "/employment-status-management";
export const APP_LOANTYPE_LIST = "/loantype-management";
export const APP_MERCHANTTYPE_LIST = "/merchanttype-management";
export const APP_OCCUPATION_LIST = "/occupation-management";
export const APP_PRODUCT_LIST = "/manage-product";
export const APP_CREATE_USER = "/user/create";
export const APP_PROFILE = "/profile";
export const APP_APP_USER_LIST = "/app-users";
export const APP_USER_VIEW = "/app-users/view";
export const APP_LOAN_VIEW = "/loans/view";
export const APP_CREATE_CONTENT = "/contents/add";
export const APP_CONTENT = "/contents";
export const APP_CREDIT_RANGE_LIST = "/credit-range";
export const APP_CREDIT_RANGE_VIEW = "/credit-range/view";

export const APP_RATE_OF_INTEREST = "/Rate-of-Interest";
export const APP_MERCHANT_LIST = "/merchantList";
export const INSTALLMENT_MANAGER = "/InstallmentManager";
export const OTP = "/OTP";
export const SET_NEW_PASSWORD = "/CreateNewPassword";
export const APP_TODAYS_EMI = "/today'semi";
export const APP_ONGOING_LOANS = '/ongoingLoans';
export const APP_NEW_LOANS = '/NewLoans'; 
export const APP_IBAN_DETAILS = '/app-users/view/IbanDetails' ; 
export const APP_FINCLUDE_DETAILS = '/app-users/view/FincludeDetails'


