import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch, useSelector } from "react-redux";
import { getLoans, changeStatus, downloadDocument } from "api/services/loanService";
import { updateToastAgent } from "app/appSlice";
import { OTP_SEND_SUCESSFULLY } from "constants/ConfirmMessage";
import { acceptLoan } from "api/services/merchantTypeService";


export default function LoanList() {
  const dispatch = useDispatch();

  const navigate = useNavigate()
  const [otpResend , setotpResend] = useState([])

  // this is for temoprary use 

  let productcarArr = ["Fashion" , "Electronics" , "Accessories" , "Toys" , "Home Appliance"]

  // till here
  
  const { data: user, loans } = useSelector((state) => state.appStore);
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const unApprovedLoans = loans?loans.filter((item)=>{return item.first_emi_received===false}): []
  // console.log(unApprovedLoans)
  


  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "CustomerModel.name",
      label: "CLIENT NAME",
      accessor: "CustomerModel.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
       
      },
    },
    {
      name: "serial_no",
      label: "INVOICE NUMBER",
      accessor: "serial_no",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        setCellProps: () => ({
          style: {
            textAlign: 'left',
            paddingLeft: '80px',
          },
        }),
      },
    },
    {
      name: "created_at",
      label: "INVOICE DATE",
      accessor: "created_at",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData)
          let date = tableMeta.rowData[2].split("T")
          return (
            <>
              <p>{date[0]}</p>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            textAlign: 'left',
            paddingLeft: '40px',
          },
        }),
      },
    },
    {
      name: "id",
      label: "PRODUCT CATEGORY",
      accessor: "id",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <p>{productcarArr[Math.floor(Math.random() * 5)]}</p>
            </>
          );
        },
      },
    },
    {
      name: "amount",
      label: "INVOICE AMOUNT",
      accessor: "amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const amount = Number(tableMeta.rowData[4]).toLocaleString();
         
          return <p>{amount}</p>;
      } , 
      setCellProps: () => ({
        style: {
          textAlign: 'right',
          paddingRight: '100px',
        },
      }),
      },
    }, 
   {
      name: "MerchantModel.id",
      label: "ACTIONS",
      accessor: "MerchantModel.id",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let merchant_id = tableMeta.rowData[5]
          let loan_invoice_id = tableMeta.rowData[1]
          return (
            <>
              <MDButton color="info" size="small"  onClick={() => {
                console.log(tableMeta.rowData)
              
                setotpResend([...otpResend , loan_invoice_id])
                // dispatch(updateToastAgent({ t: "s", m: OTP_SEND_SUCESSFULLY}));
                dispatch(acceptLoan({ merchant_id }));
                 
                }}  >
                {!otpResend.includes(loan_invoice_id)?'CONFIRM SALE' : 'RESEND OTP'}
              </MDButton>
            </>
          );
        },
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    enableNestedDataAccess: ".",
    selectableRows: "none",
  };

  useEffect(() => {
    dispatch(getLoans());
  }, []);

  const handleDownloadDocument = (id) => {
    dispatch(downloadDocument({ id }));
    dispatch(getLoans());
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {loans && (
                <MUIDataTable
                  title={"Loans"}
                  data={unApprovedLoans}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
