import { axiosPrivate } from "api/axios";
import {
    updateLoader,
    updateToastAgent,
    setProducts,
    setProduct
} from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getProducts = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_PRODUCTS);
        if (response.data.status_code == 200) {
            dispatch(setProducts(response.data.data));
        } else {
            dispatch(setProducts([]));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}


export const getProduct = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let response = await axiosPrivate.get(API.GET_PRODUCT({ id: req.id }));

        if (response.data.status_code == 200) {
            let productData = {
                id: response.data.data.id,
                name: response.data.data.name,
                barcode: response.data.data.barcode,
                description: response.data.data.description,
                category: response.data.data.category,
                price: response.data.data.price,
                color: response.data.data.color,
                size: response.data.data.size,
            }
            dispatch(setProduct(productData));
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const updateProduct = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        // req this is from data object
        dispatch(updateLoader(true));
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        let results = await axiosPrivate.post(API.GET_PRODUCT({ id: req.get('id') }), req, config);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const addProduct = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        let results = await axiosPrivate.post(API.GET_PRODUCTS, req, config);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const importProduct = (req, redirectTo, navigate) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        let results = await axiosPrivate.post(API.IMPORT_PRODUCTS, req, config);
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
            if (redirectTo) {
                navigate(redirectTo);
            }
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const deleteProduct = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.DELETE_PRODUCT({ id: req.id }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        } else if (results.data.status_code == 201) {
            dispatch(updateToastAgent({ t: "e", m: results.data.message, time: "15000" }))
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}

export const changeStatus = (req) => async (dispatch) => {
    try {
        dispatch(updateLoader(true));
        let results = await axiosPrivate.get(API.PRODUCT_CHANGE_STATUS({ id: req.id, status: req.status }));
        if (results.data.status_code == 200) {
            dispatch(updateToastAgent({ t: "s", m: results.data.message }));
        }
        else {
            dispatch(updateToastAgent({ t: "e", m: results.data.message }))
        }
        dispatch(updateLoader(false));
    } catch (error) {
        dispatch(updateToastAgent({ t: "e", m: error.message }));
        dispatch(updateLoader(false));
    }
}