import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmploymentStatus } from "app/appSlice";
import { getEmploymentStatus, updateEmploymentStatus } from "api/services/employmentStatusService";
import { APP_EMPLOYMENT_STATUS_LIST } from "constants/AppRouteName";
import { employmentStatusValidationSchema } from "app/validationSchema";
import { Formik, Form, ErrorMessage } from "formik";
import { updateToastAgent } from "app/appSlice";
import { initialState } from "app/initialState";
import * as Yup from "yup";

export default function EditEmploymentStatus() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { employmentstatus } = useSelector((state) => state.appStore);
    const { employmentstatuses } = useSelector((state) => state.appStore);
    const { name } = employmentstatus ?? initialState.employmentstatus;

    useEffect(() => {
        dispatch(getEmploymentStatus({ id }));
    }, [id]);

    const initialValues = {
        name: name || "",
    };



    const handleUpdate = async (values, actions) => {
        const isDuplicate = employmentstatuses.some((status) => status.name.toLowerCase() === values.name.toLowerCase());
        if (isDuplicate) {
            dispatch(updateToastAgent({ t: "e", m: "Employment Status already exists" }));
        } else {
            dispatch(updateEmploymentStatus({ ...employmentstatus, name: values.name }, APP_EMPLOYMENT_STATUS_LIST, navigate));
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" textAlign="center">
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Edit Employment Status
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Enter details to Edit
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={employmentStatusValidationSchema}
                        onSubmit={(values, actions) => handleUpdate(values, actions)}
                        enableReinitialize
                    >
                        {(formik) => (
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                label="Name"
                                                name="name"
                                                variant="standard"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                fullWidth
                                                className={formik.errors.name && formik.touched.name ? "input-error" : null}
                                            />
                                            <ErrorMessage name="name" component="span" className="error" />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} mt={4} mb={1} display="flex" justifyContent="center">
                                        <MDButton variant="gradient" color="info" type="submit">
                                            Update
                                        </MDButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </MDBox>
            </Card>
        </DashboardLayout>
    );
}
