import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { acceptAppliedLoan, getLoans } from '../../api/services/loanService';
import MDButton from "components/MDButton";
import ConfirmBox from "customComponents/general/ConfirmBox";
import { flattenArrayOfObjects } from "../../api/services/loanService";


export default function PendingLoanApplications() {
  const dispatch = useDispatch();
  const { loans } = useSelector((state) => state.appStore);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [handleConfirmMsg, setHandleConfirmMsg] = useState('')



  const tableDataArray = loans ? flattenArrayOfObjects(loans) : []




  const handleAccept = (id) => {
    setId(id);
    setOpen(true);
    setHandleConfirmMsg(1)
  }
  const handleOption = async () => {
    let status = loans.filter((item) => { return item.id === id })[0].status
    let acceptLoanData = {
      first_emi_received: true,
      status: 1,
      invoice_id: id,
      customer_id: loans[0].CustomerModel.id,
      merchant_id: loans[0].MerchantModel.id,
    }

    setOpen(false);
    // acceptAppliedLoan(dispatch , acceptLoanData)
    // dispatch(getLoans());
    function myPromiseFunction(dispatch, acceptLoanData) {
      return new Promise((resolve, reject) => {
        acceptAppliedLoan(dispatch, acceptLoanData)
          .then(() => {

            dispatch(getLoans());
          })
          .then(() => {

            resolve();
          })
          .catch(error => {

            reject(error);
          });
      });
    }


    myPromiseFunction(dispatch, acceptLoanData)


  }

  const handleReject = (id) => {
    setId(id);
    setOpen(true);
    setHandleConfirmMsg(0)

  }

  const rejectLoan = () => {

    let acceptLoanData = {
      first_emi_received: false,
      status: 2,
      invoice_id: id,
      customer_id: loans[0].CustomerModel.id,
      merchant_id: loans[0].MerchantModel.id,
    }
    setOpen(false);
    function myPromiseFunction(dispatch, acceptLoanData) {
      return new Promise((resolve, reject) => {
        acceptAppliedLoan(dispatch, acceptLoanData)
          .then(() => {

            dispatch(getLoans());
          })
          .then(() => {

            resolve();
          })
          .catch(error => {

            reject(error);
          });
      });
    }


    myPromiseFunction(dispatch, acceptLoanData)
  }




  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [

    {
      name: "CustomerModel.name",
      label: " Name",
      accessor: "CustomerModel.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "amount",
      label: "Loan Amount",
      accessor: "amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const LoanAmount = Number(tableMeta.rowData[1]).toLocaleString();

          return <p>{LoanAmount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),
      },
    },
    {
      name: "product_amount",
      label: "Product Amount",
      accessor: "product_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const product_amount = Number(tableMeta.rowData[2]).toLocaleString();

          return <p>{product_amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),
      },
    },
    {
      name: "installments",
      label: "Total Number of EMI",
      accessor: "installments",
      options: {
        filter: true,
        sort: true,
        empty: true,
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),
      },
    },
    {
      name: "first_installment_amount",
      label: "Installment Amount",
      accessor: "first_installment_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const Installment_Amount = Number(tableMeta.rowData[4]).toLocaleString();

          return <p>{Installment_Amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            paddingRight: '60px',
          },
        }),

      },
    },
    {
      name: "id",
      label: "Action",
      accessor: "id",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[5];
          let loan = loans.find(item => item.id === id);
          // console.log(tableMeta.rowData)


          if (loan.status !== 2) {
            return (
              <>
                <MDButton color='success' size='small' onClick={() => { handleAccept(id) }}>Confirm</MDButton>
                <MDButton color='error' size='small' onClick={() => { handleReject(id) }}>Reject</MDButton>
              </>
            );
          } else {
            return <p>Rejected</p>;
          }
        }

      },
    },

  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
    resizableColumns: false

  };


  useEffect(async () => {

    dispatch(getLoans())

  }, []);






  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {loans != null && (
                <MUIDataTable
                  title={"Manage Loans"}
                  data={tableDataArray}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        message={handleConfirmMsg === 1 ? 'are you sure ? you want to confirm Loan ?' : 'are you sure ? you want to reject Loan ?'}
        handleOption={handleConfirmMsg === 1 ? () => handleOption() : () => rejectLoan()}
      />

    </DashboardLayout>
  );
}
