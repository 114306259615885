import Icon from "@mui/material/Icon";
import * as ROUTE from "constants/AppRouteName";
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";


// Product
import CreateProduct from "customComponents/product/CreateProduct";
import ManageProduct from "customComponents/product/ManageProduct";
import ImportProducts from "customComponents/product/ImportProducts";
import EditProduct from "customComponents/product/EditProduct";

// Loan details
import ManageLoan from "customComponents/loan/list";
import LoanView from "customComponents/loan/view";
import LoanListHistory from "customComponents/loan/history";


const merchantRoutes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
        nave: "sidebar"
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: ROUTE.APP_PROFILE,
      component: <Profile />,
      nave:"internal"
    },
    // {
    //   type: "collapse",
    //   name: "Product Manager",
    //   key: "manage-product",
    //   icon: <Icon fontSize="small">inventory</Icon>,
    //   route: "/manage-product",
    //   component: <ManageProduct />,
    //   nave:"sidebar"
    // },
    {
      type: "collapse",
      name: "Create Product",
      key: "createproduct",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/create-product",
      component: <CreateProduct />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Import Products",
      key: "importproducts",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/import-products",
      component: <ImportProducts />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Edit Product",
      key: "editproduct",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/product/edit/:id",
      component: <EditProduct />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Transcation Page",
      key: "loans",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/loans",
      component: <ManageLoan />,
      nave:"sidebar"
    },
    {
      type: "collapse",
      name: "Loan View",
      key: "loan view",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/loans/view/:id",
      component: <LoanView />,
      nave:"internal"
    },
    {
      type: "collapse",
      name: "Loan history",
      key: "loans/history",
      icon: <Icon fontSize="small">inventory</Icon>,
      route: "/loans/history",
      component: <LoanListHistory />,
      nave:"sidebar"
    },
];

export default merchantRoutes;