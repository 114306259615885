import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "app/initialState";



export default function UserIbanDetails() {

    const { id } = useParams();
    const { customer } = useSelector((state) => state.appStore);
    let {
        bank_account,
        bank_name,
        bban,
        iban,
        first_name , 
        last_name
    } = customer ?? initialState.customer;

    // console.log(customer);




    return (
        // <CoverLayout image={bgImage}>
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    {first_name +" " + last_name}
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                       Iban Details
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                            
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="bank_account"
                                        label="Bank account"
                                        name="bank_account"
                                        value={bank_account}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="bank_name"
                                        label="Bank Name"
                                        name="bank_name"
                                        value={bank_name}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="Bban Number"
                                        name="bban"
                                        value={bban}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                    <MDInput
                                        type="text"
                                        label="Iban Number"
                                        name="iban"
                                        value={iban}
                                        variant="standard"
                                        readOnly={true}
                                        fullWidth
                                    />
                                </MDBox>
                            </Grid>
                 
                        </Grid>
                    </MDBox>

                </MDBox>
            </Card>
        </DashboardLayout>
        // </CoverLayout>
    );
}
