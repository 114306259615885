import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch, useSelector } from "react-redux";
import { getLoans, changeStatus, downloadDocument } from "api/services/loanService";
import { updateToastAgent } from "app/appSlice";
import { OTP_SEND_SUCESSFULLY } from "constants/ConfirmMessage";

export default function LoanListHistory() {
  const dispatch = useDispatch();

  const navigate = useNavigate()

  // this is for temoprary use 

  let productcarArr = ["Fashion", "Electronics", "Accessories", "Toys", "Home Appliance"]

  // till here

  const { loans } = useSelector((state) => state.appStore);



  const ApprovedLoans = loans ? loans.filter((item) => { return item.first_emi_received === true }) : []

  console.log(ApprovedLoans)
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });


  console.log(ApprovedLoans)


  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "CustomerModel.name",
      label: "CLIENT NAME",
      accessor: "CustomerModel.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,

      },
    },
    {
      name: "serial_no",
      label: "INVOICE NUMBER",
      accessor: "serial_no",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        setCellProps: () => ({
          style: {
            textAlign: 'left',
            paddingLeft: '80px',
          },
        }),
      },
    },
    {
      name: "created_at",
      label: "INVOICE DATE",
      accessor: "created_at",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta.rowData)
          let date = tableMeta.rowData[2].split("T")
          return (
            <>
              <p>{date[0]}</p>
            </>
          );
        },
        setCellProps: () => ({
          style: {
            textAlign: 'left',
            paddingLeft: '40px',
          },
        }),
      },
    },
    // {
    //   name: "id",
    //   label: "PRODUCT CATEGORY",
    //   accessor: "id",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     empty: true,
    //     display: true,
    //     viewColumns: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       // console.log(tableMeta)
    //       return (
    //         <>
    //           <p>{productcarArr[Math.floor(Math.random() * 5)]}</p>
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      name: "product_amount",
      label: "INVOICE AMOUNT",
      accessor: "product_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          const amount = Number(tableMeta.rowData[3]).toLocaleString();



          return <p>{amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: 'center',
            paddingRight: '100px',
          },
        }),
      },
    }
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    enableNestedDataAccess: ".",
    selectableRows: "none",
  };

  useEffect(() => {
    dispatch(getLoans());
  }, []);

  const handleDownloadDocument = (id) => {
    dispatch(downloadDocument({ id }));
    dispatch(getLoans());
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        {/* <MDBox align="right" marginRight="5%" marginTop="1%" marginBottom="1%">
          <MDButton align="right" color="info" size="small" onClick={()=>{navigate('/loans/history')}}>VIEW History</MDButton>
        </MDBox> */}
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {loans && (
                <MUIDataTable
                  title={"Loan History"}
                  data={ApprovedLoans}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
